import { HourlyForecast } from '../../types/Forecast';
import {
    _getCompareLineData,
    createUniqueLabels,
    sortDisplayDates,
} from '../../utils/CommonUtils';
import LineChartCard from '../cards/LineChartCard';
import { SelectedHour } from '../../types/LineChart';

type ClimbitChartProps = {
  hourlyForecasts: HourlyForecast[];
  selectedArea?: string;
  weeklyHourlyForecast?: HourlyForecast[][];
  showTitle?: boolean;
  showLegend?: boolean;
  showWeeklyForecast: boolean;
  color?: string;
  height?: string;
  shadow?: boolean;
  padding?: string;
  multipleAreas?: boolean;
  title?: string;
  updateSelectedHour?: (point: SelectedHour) => void;
};

const ClimbitChart = ({
    hourlyForecasts,
    selectedArea,
    weeklyHourlyForecast,
    showWeeklyForecast,
    color,
    showTitle = true,
    showLegend = true,
    height = 'h-[15rem]',
    shadow = true,
    padding = 'py-2 md:px-4 px-2',
    multipleAreas = false,
    title = 'Climbit Score',
    updateSelectedHour,
}: ClimbitChartProps) => {
    const uniqueLabels: string[] = createUniqueLabels(
        hourlyForecasts,
        'displayTime',
    );
    const repeatingLabels: string[] = weeklyHourlyForecast
        ? weeklyHourlyForecast[0]?.map(({ displayTime }) => displayTime)
        : [];
    const labels: string[] = showWeeklyForecast
        ? repeatingLabels
        : sortDisplayDates(uniqueLabels);
    const timeValues = showWeeklyForecast ? undefined : labels;
    const subLabels = showWeeklyForecast
        ? Array.from(
            new Set(
                weeklyHourlyForecast?.flat().map(({ displayDate }) => displayDate),
            ),
        )
        : null;
    const areas =
    showWeeklyForecast && weeklyHourlyForecast
        ? weeklyHourlyForecast.flat()
        : hourlyForecasts;

    return (
        <>
            <div
                className={`${padding} flex flex-col items-center justify-evenly min-${height} w-full`}
            >
                <div className="flex w-full">
                    {areas && labels && (
                        <LineChartCard
                            title={title}
                            key={'climbitScore'}
                            labels={labels}
                            lineData={_getCompareLineData(
                                areas,
                                'climbit',
                                'climbitStars',
                                selectedArea,
                                'displayTime',
                                timeValues,
                                color,
                                multipleAreas ? undefined : 'Climbit Score',
                            )}
                            yMin={0}
                            yMax={5}
                            maintainAspectRatio={false}
                            height={height}
                            subLabels={subLabels}
                            labelSuffix="★"
                            showTitle={showTitle}
                            legend={showLegend}
                            shadow={shadow}
                            yTitle={'Stars'}
                            updateSelectedHour={updateSelectedHour}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ClimbitChart;
