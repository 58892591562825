import { Helmet } from 'react-helmet';
import KayaWidget from '../components/widgets/kaya-widget/KayaWidget';

const WidgetKaya = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    return (
        <div className='w-full h-full'>
            <Helmet>
                {!currentUrl.includes('/redirect') && <link rel="canonical" href={currentUrl} />}
            </Helmet>
            <div className='justify-center mt-4 w-full h-full'>
                <KayaWidget/>
            </div>   
        </div>
    );
};

export default WidgetKaya;
