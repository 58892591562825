import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { LOCATION_DAILY_HOURLY_CURRENT_FORECAST } from '../../graphql/areas/queries';
import { singleAreaState, showWeeklyForecastState } from '../../state/areasState';
import Loading from '../common/Loading';
import Title from '../common/Title';
import CardCarousel from '../cards/CardCarousel';
import { navState } from '../../state/navState';
import MissingPageComponent from '../common/MissingPage';
import { Toaster } from 'react-hot-toast';
import LocationHeader1 from '../../images/KyleHeader.png';
import LocationHeader2 from '../../images/ShockHeader.png';
import HourlyForecasts from '../Forecast/HourlyForecasts';
import { HourlyForecast, DailyForecast, PastPrecip } from '../../types/Forecast';
import PastPrecipComponent from '../Forecast/PastPrecip';
import GoogleMapsButton from '../buttons/GoogleMapsButton';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { weatherModelState } from '../../state/weatherModelState';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import HourlyForecastCardComponent from '../cards/HourlyForecastCard';
import HourlyForecastTable from '../Forecast/HourlyForecastTable';
import { unitPreferencesState } from '../../state/unitPreferencesState';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import { sunShadeState } from '../../state/sunShadeState';
import { useQuery } from '@apollo/client';
import { createUniqueId } from '../../utils/CommonUtils';
import ShareButton from '../buttons/ShareButton';
import ViewSelector from '../common/ViewSelector';

const Location = () => {
    const params: any = useParams();
    const daytimeOnly = useRecoilValue(daytimeOnlyState);
    const weatherModel = useRecoilValue(weatherModelState);
    const pastPrecipHours = useRecoilValue(pastPrecipHoursState);
    const {temperaturePreferences, metricUnits} = useRecoilValue(unitPreferencesState);
    const [isMounted, setIsMounted] = useState(false);
    const [chosenLocationHeader, setChosenLocationHeader] = useState('');
    const [location, setLocation] = useRecoilState<any>(singleAreaState(params.id));
    const sunShade = useRecoilValue(sunShadeState);

    useEffect(() => {
        // Generate a random number between 0 and 1
        const randomIndex = Math.random() < 0.5 ? 0 : 1;
  
        // Choose the locationHeader based on the random number
        const locationHeader = randomIndex === 0 ? LocationHeader1 : LocationHeader2;
  
        // Set the chosenLocationHeader state to the selected LocationHeader
        setChosenLocationHeader(locationHeader);

        // This code will run when the component mounts
        setIsMounted(true);
  
        // Cleanup function (optional)
        return () => {
            // This code will run when the component unmounts
            setIsMounted(false);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        if (location) {
            document.title = `${location.latitude}, ${location.longitude} | Rock Climbing Weather Forecasts`;
        }
    }, [location]);


    useEffect(() => {
        if (data && !loading) {
            refetchQuery();
        }
      
    }, [daytimeOnly, weatherModel, pastPrecipHours, temperaturePreferences, metricUnits, sunShade]); // Run this effect whenever preferences change

    const { data, loading, error, refetch: refetchQuery } = useQuery(LOCATION_DAILY_HOURLY_CURRENT_FORECAST, {
        variables: {
            name: createUniqueId(Number(params.latitude), Number(params.longitude)),
            latitude: params.latitude,
            longitude: params.longitude,
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits,
            sunShade: sunShade[params.id] || 0
        }
    });

    const resetNavState = useResetRecoilState(navState);
    const dailyForecastRef = useRef<HTMLDivElement>(null);

    const [currentDayIndex, setCurrentDayIndex] = useState<number>(0);
    const [currentDay, setCurrentDay] = useState<DailyForecast | null>(null);
    const [dailyForecast, setDailyForecast] = useState<DailyForecast[]>([]);
    const [hourlyForecast, setHourlyForecast] = useState<HourlyForecast[]>([]);
    const [currentHourlyForecast, setCurrentHourlyForecast] = useState<HourlyForecast | null>(null);
    const [weeklyHourlyForecast, setWeeklyHourlyForecast] = useState<HourlyForecast[][]>([]);
    const [selectedView, setSelectedView] = useState('graph'); 
    const forecastLength = Object.keys(dailyForecast).length;

    const [pastPrecip, setPastPrecip] = useState<PastPrecip | undefined>(undefined);
    const bestTimesToClimb =
    location?.forecasts?.bestTimesToClimb?.hourlyForecast;
    const [showWeeklyForecast, setShowDailyForecast] = useRecoilState(showWeeklyForecastState);

    useEffect(() => {
        resetNavState();
        const isValid = !!data?.location?.forecasts;
        if (isValid) {
            setLocation(data.location);
            if (data.location.forecasts.pastPrecip) setPastPrecip(data.location.forecasts.pastPrecip);
            if (data.location.forecasts.dailyForecast && data.location.forecasts.dailyForecast[0]) setCurrentDay(data.location.forecasts.dailyForecast[0]);
            if (data.location.forecasts.dailyForecast) setDailyForecast(data.location.forecasts.dailyForecast);
            if (data.location.forecasts.currentWeather) setCurrentHourlyForecast(data.location.forecasts.currentWeather);
        }
    }, [data]);

    useEffect(() => {
        if (!location) return;
        setCurrentDay(dailyForecast[currentDayIndex]);
    }, [currentDayIndex, dailyForecast, location]);

    useEffect(() => {
        if (!currentDay) return;
        setHourlyForecast(
            location?.forecasts?.hourlyForecast?.filter(
                ({ date }: HourlyForecast) => date === currentDay.date
            )
        );
        setWeeklyHourlyForecast([location?.forecasts?.hourlyForecast?.filter((_: any, i: number) => i % 4 === 0 )]);
    }, [currentDay, location]);

    if (loading) return <Loading />;
    if (isNaN(params.latitude) || isNaN(params.longitude)) return <MissingPageComponent />;
    if (error && error?.message.includes('429')) return <TooManyRequestsComponent/>;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent/>;
    if (!location && !loading && isMounted) return <MissingPageComponent />;

    return (
        <div className="bg-white-secondary pb-4">
            <div className='flex flex-col justify-center items-center'>
                <div className="flex flex-col md:flex-row w-full md:justify-between md:pr-8">
                    <Title title={`${location.latitude},${location.longitude}`} header={location?.path} />
                    <div className='flex flex-col items-center justify-center md:space-y-6'>
                        <div className="flex lg:justify-start justify-center items-center">
                            {location && (
                                <div className="md:mr-6 p-2 flex gap-x-2">
                                    <Toaster
                                        position="top-center"
                                        reverseOrder={false}
                                        gutter={8}
                                        containerClassName="toast"
                                        containerStyle={{}}
                                        toastOptions={{
                                            className: '',
                                            duration: 3500,
                                            style: {
                                                background: '#363636',
                                                color: '#fff',
                                            }
                                        }}/>     
                                    {pastPrecip !== undefined && (
                                        <PastPrecipComponent pastPrecip={pastPrecip}/>
                                    )}
                                    {location?.googleMaps && (
                                        <GoogleMapsButton areaCity={location}/>
                                    )}
                                    <ShareButton title={`${location.latitude}, ${location.longitude} | Climbit Weather Forecast`} url={window.location.href} />
                                </div>
                            )}                      
                        </div>
                    </div>
                </div>
            </div>
            
            {currentDay && (
                <>
                    <div className="flex flex-col md:flex-row justify-center md:mx-4 select-none md:mt-6">
                        <div className="flex flex-col md:flex-row pb-3 hide-scroll-bar justify-center">     
                            <div className="relative flex justify-center items-center w-full">
                                <div className="absolute inset-0 bg-black opacity-30"></div>
                                <img
                                    src={chosenLocationHeader}
                                    className="rounded-lg h-full min-h-[370px] object-cover w-full hidden md:block"
                                    alt="image"
                                />
                                {currentHourlyForecast && (
                                    <>

                                        <div className={`md:absolute md:right-0 md:top-0 md:mr-20 h-full ${currentHourlyForecast.isDay ? 'md:bg-white': 'md:bg-gray-light'} justify-center items-center hidden md:flex`}>
                                            <HourlyForecastCardComponent
                                                key={currentHourlyForecast.displayTime}
                                                forecast={currentHourlyForecast}
                                                currentWeather={true}
                                                className='md:py-2'
                                                overImage={true}
                                            />
                                        </div>
                                        <div className="md:hidden relative w-full bg-white-secondary pt-4 px-2">
                                            <HourlyForecastTable
                                                forecasts={[currentHourlyForecast]}
                                                currentWeather={true}
                                            /> 
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>)}

            {dailyForecast && (
                <div className="flex flex-col p-1 hide-scroll-bar flex-shrink-0 items-center justify-center">
                    <CardCarousel dailyForecasts={dailyForecast} currentDayIndex={currentDayIndex} onClick={(id: number) => setCurrentDayIndex(id)} refToScroll={dailyForecastRef} bestTimesToClimb={bestTimesToClimb} allHourlyForecasts={location?.forecasts?.hourlyForecast}/>
                </div>)}
            <div className="flex flex-col m-auto w-full" id="dailyForecast" ref={dailyForecastRef}>
                {hourlyForecast && currentDay && weeklyHourlyForecast && (
                    <>
                        <ViewSelector selectedView={selectedView} setSelected={setSelectedView} showWeeklyForecast={showWeeklyForecast} setShowDailyForecast={setShowDailyForecast} currentDay={currentDay.date} currentDayIndex={currentDayIndex} setCurrentDayIndex={setCurrentDayIndex} forecastLength={forecastLength}/>
                        <HourlyForecasts
                            forecasts={hourlyForecast}
                            bestTimeToClimb={
                                bestTimesToClimb
                                    ? bestTimesToClimb?.find(
                                        ({ date }: HourlyForecast) => date === currentDay.date
                                    )
                                    : undefined
                            }
                            sunrise={currentDay ? new Date(currentDay.sunrise) : undefined}
                            sunset={currentDay ? new Date(currentDay.sunset) : undefined}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            selectedView={selectedView}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Location;
