import { useRecoilValue } from 'recoil';
import { HourlyForecast } from '../../types/Forecast';
import { LineChartData } from '../../types/LineChart';
import {
    _getCompareLineData,
    createUniqueLabels,
    lineColors,
    sortDisplayDates,
} from '../../utils/CommonUtils';
import LineChartCard from '../cards/LineChartCard';
import { temperatureUnitSelector } from '../../state/unitPreferencesState';
import { getTemperatureUnits } from '../../utils/WeatherUtils';
import { SelectedHour } from '../../types/LineChart';

type TemperatureChartProps = {
  hourlyForecasts: HourlyForecast[];
  selectedArea?: string;
  weeklyHourlyForecast?: HourlyForecast[][];
  showWeeklyForecast: boolean;
  color?: string;
  showTitle?: boolean;
  showLegend?: boolean;
  height?: string;
  shadow?: boolean;
  padding?: string;
  isMetric?: boolean;
  yMax?: number;
  yMin?: number;
  updateSelectedHour?: (point: SelectedHour) => void;
};

const TemperatureChart = ({
    hourlyForecasts,
    selectedArea,
    weeklyHourlyForecast,
    showWeeklyForecast,
    color,
    showTitle = true,
    showLegend = true,
    height = 'h-[15rem]',
    shadow = true,
    padding = 'py-2 md:px-4 px-2 flex',
    isMetric,
    yMax,
    yMin,
    updateSelectedHour,
}: TemperatureChartProps) => {
    const uniqueLabels: string[] = createUniqueLabels(
        hourlyForecasts,
        'displayTime',
    );
    const repeatingLabels: string[] = weeklyHourlyForecast
        ? weeklyHourlyForecast[0]?.map(({ displayTime }) => displayTime)
        : [];
    const labels: string[] = showWeeklyForecast
        ? repeatingLabels
        : sortDisplayDates(uniqueLabels);
    const subLabels = showWeeklyForecast
        ? Array.from(
            new Set(
                weeklyHourlyForecast?.flat().map(({ displayDate }) => displayDate),
            ),
        )
        : null;
    const temperatureUnit =
    isMetric !== undefined
        ? getTemperatureUnits(isMetric)
        : useRecoilValue(temperatureUnitSelector);

    const lineData: LineChartData[] = showWeeklyForecast
        ? _getCompareLineData(
            weeklyHourlyForecast?.flat(),
            'climbit',
            'climbitStars',
            selectedArea,
            'displayTime',
            undefined,
            color,
        )
        : _getCompareLineData(
            hourlyForecasts,
            'climbit',
            'climbitStars',
            selectedArea,
            'displayTime',
            labels,
            color,
        );

    return (
        <>
            <div
                className={`${padding} flex flex-col items-center justify-evenly w-full min-${height}`}
            >
                <div className="flex w-full">
                    {lineData && labels && (
                        <LineChartCard
                            title="Temperature"
                            showTitle={showTitle}
                            legend={showLegend}
                            key={'hourlyForecast_2'}
                            labels={labels}
                            lineData={[
                                {
                                    color: lineColors.tempLineColor,
                                    labelDescription: 'Temperature',
                                    values:
                    showWeeklyForecast && weeklyHourlyForecast
                        ? weeklyHourlyForecast
                            .flat()
                            .map(({ temperature }) => temperature)
                        : hourlyForecasts.map(({ temperature }) => temperature),
                                },
                                {
                                    color: lineColors.feelsLikeLineColor,
                                    labelDescription: 'Feels Like',
                                    values:
                    showWeeklyForecast && weeklyHourlyForecast
                        ? weeklyHourlyForecast
                            .flat()
                            .map(({ apparentTemperature }) => apparentTemperature)
                        : hourlyForecasts.map(
                            ({ apparentTemperature }) => apparentTemperature,
                        ),
                                },
                            ]}
                            maintainAspectRatio={false}
                            height={height}
                            subLabels={subLabels}
                            labelSuffix={temperatureUnit}
                            shadow={shadow}
                            yMax={yMax !== -Infinity ? yMax : undefined}
                            yMin={yMin}
                            yTitle={isMetric ? 'Celsius' : 'Fahrenheit'}
                            updateSelectedHour={updateSelectedHour}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default TemperatureChart;
