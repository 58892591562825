import { gql } from '@apollo/client';

export const HOURLY_FORECAST_FRAGMENT = gql`
    fragment hourlyForecastFragment on HourlyForecast {
        areaName,
        areaId,
        time,
        displayTime,
        date,
        displayDate,
        temperature,
        apparentTemperature,
        relativeHumidity,
        windSpeed,
        cloudCover,
        precipitationProbability,
        dewpoint,
        quantitativePrecipitation,
        snowfall,
        snowDepth,
        rainfall,
        weatherCode,
        weatherCodeDescription,
        windDirection,
        uvIndex,
        isDay,
        sunshineDurationMinutes,
        climbit {
            climbitScore,
            climbitScorePercentage,
            climbitStars
        }
    }  
`;

export const WEATHER_CODES_FRAGMENT = gql`
    fragment weatherCodesFragment on WeatherCodes {
        overall {
            code
            description
        }
        am {
            code
            description
        }
        pm {
            code
            description
        }
    } 
`;

export const DAILY_FORECAST_FRAGMENT = gql`
    fragment dailyForecastFragment on DailyForecast {
        areaId
        areaName
        weatherCodes {
            ...weatherCodesFragment
        }
        maxTemperature
        minTemperature
        minApparentTemperature
        maxApparentTemperature
        sunset
        sunrise
        precipitationHours
        quantitativePrecipitation
        snowfallSum
        rainfallSum
        time
        date
        displayDate
    }
        ${WEATHER_CODES_FRAGMENT}
`;

export const BEST_TIMES_TO_CLIMB_FRAGMENT = gql`
    fragment bestTimesToClimbFragment on Forecasts {
       bestTimesToClimb {
            dates
            hourlyForecast {
                ...hourlyForecastFragment
            }  
       }   
    } 
    ${HOURLY_FORECAST_FRAGMENT}
`;

export const PAST_PRECIP_FRAGMENT = gql`
    fragment pastPrecipFragment on Forecasts {
        pastPrecip {
            pastPrecipAmounts {
                snow
                rain
                total
                displayDate
                date
            }
            pastPrecipRunningTotals {
                snow
                rain
                total
            }
            lastPrecip {
                time
                amount {
                    snow
                    rain
                    total
                }
            }
        } 
    }
`;

