import { WeatherCodes } from '../../../types/Forecast';
import { weatherCodesMap } from '../../Forecast/WeatherIcons';
import { BsThermometerHigh, BsThermometerLow } from 'react-icons/bs';
import { FaSnowflake } from 'react-icons/fa6';
import { WiRaindrop } from 'react-icons/wi';
import {
    getDisplayPrecipitation,
    getPrecipUnits,
} from '../../../utils/WeatherUtils';

export type DailyForecastData = {
  dayIndex: number;
  date: string;
  displayDate: string;
  maxTemperature: number;
  minTemperature: number;
  rainfallSum: number;
  snowfallSum: number;
  quantitativePrecipitation: number;
  precipitationHours: number;
  weatherCodes: WeatherCodes;
  peakClimbitScore: number;
  peakTime: string;
};

type DailyDataProps = {
  dailyForecastData: DailyForecastData;
  isMetric: boolean;
};

const DailyData: React.FC<DailyDataProps> = ({
    dailyForecastData,
    isMetric,
}) => {
    const currentDay = dailyForecastData;
    const precipUnit = getPrecipUnits(isMetric);

    if (!currentDay) {
        return null;
    }

    return (
        <div className="flex flex-col w-full space-y-1">
            <div className="flex flex-row justify-between items-center">
                <div className="basis-1/2 flex flex-col space-y-1">
                    <div className="font-semibold text-2xl">
                        {currentDay?.displayDate ?? ''}
                    </div>
                    <div className="text-xl flex items-center">
                        <span>
                            {currentDay?.maxTemperature.toFixed() ?? ''}°{isMetric ? 'C' : 'F'}
                        </span>
                        <BsThermometerHigh className="mr-2" />
                        <span className="">
                            {currentDay?.minTemperature.toFixed() ?? ''}°{isMetric ? 'C' : 'F'}
                        </span>
                        <BsThermometerLow />
                    </div>
                </div>
                <div className="basis-1/2 flex flex-row space-x-1 justify-end">
                    <div className="flex flex-col items-center">
                        <div>
                            {weatherCodesMap(true, 50).get(
                                currentDay?.weatherCodes.am?.code.toString() ?? '0',
                            )}
                        </div>
                        <div>am</div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div>
                            {weatherCodesMap(true, 50).get(
                                currentDay?.weatherCodes.pm?.code.toString() ?? '0',
                            )}
                        </div>
                        <div>pm</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex text-sm">
                    <span className="font-semibold">Total Precip</span>:{' '}
                    <div className="flex">
                        {((currentDay?.rainfallSum > 0 && !currentDay.snowfallSum) ||
              (!currentDay.rainfallSum && !currentDay.snowfallSum)) && (
                            <div className="whitespace-nowrap flex items-center">
                                <WiRaindrop size={12} className="scale-[2] mt-0.5 mx-0.5" />
                                {getDisplayPrecipitation(currentDay?.rainfallSum, isMetric)}
                                {precipUnit} | {currentDay?.precipitationHours} hour(s)
                            </div>
                        )}
                        {currentDay.snowfallSum > 0 && !currentDay.rainfallSum && (
                            <>
                                <FaSnowflake size={12} className="mr-1" />
                                {getDisplayPrecipitation(currentDay?.snowfallSum, isMetric)}
                                {isMetric ? 'cm' : precipUnit} |{' '}
                                {currentDay?.precipitationHours} hour(s)
                            </>
                        )}
                        {currentDay.snowfallSum > 0 && currentDay.rainfallSum > 0 && (
                            <div className="whitespace-nowrap flex items-center">
                                <WiRaindrop size={12} className="scale-[2] mt-0.5 mx-0.5" />
                                {getDisplayPrecipitation(currentDay?.rainfallSum, isMetric)}
                                {precipUnit} | <FaSnowflake size={12} className="mx-1" />
                                {getDisplayPrecipitation(currentDay?.snowfallSum, isMetric)}
                                {isMetric ? 'cm' : precipUnit} |{' '}
                                {currentDay?.precipitationHours}
                hr(s)
                            </div>
                        )}
                    </div>
                </div>
                <div className="text-base font-semibold">
          Peak Climbit Score: {currentDay?.peakClimbitScore.toFixed(1) ?? ''}★ at{' '}
                    {currentDay?.peakTime.replaceAll(' ', '').toLowerCase() ?? ''}
                </div>
            </div>
            <hr />
        </div>
    );
};

export default DailyData;
