import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import NavbarComponent from './components/navbar/Navbar';
import AreaPage from './pages/AreaPage';
import AreasPage from './pages/AreasPage';
import ComparePage from './pages/ComparePage';
import MissingPageComponent from './components/common/MissingPage';
import HomePage from './pages/HomePage';
import AboutBlog from './pages/blogPosts/AboutBlog';
import TermsAndConditions from './pages/TermsAndConditions';
import Footer from './components/footer/Footer';
import ReactGA from 'react-ga4';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BugReport from './pages/BugReport';
import FeatureRequest from './pages/FeatureRequest';
import DownForMaintenance from './pages/DownForMaintenance';
import ScrollToTop from './components/common/ScrollToTop';
import CityPage from './pages/CityPage';
import WidgetDailyForecast from './pages/WidgetDailyForecastPage';
import WidgetKaya from './pages/WidgetKayaPage';
import LocationPage from './pages/LocationPage';
import { useRecoilState } from 'recoil';
import { compareAreasIdNameState } from './state/areasState';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AREAS_ID_NAME_BY_IDS } from './graphql/areas/queries';

function App() {

    const inMaintenanceMode = process.env.REACT_APP_MAINTENANCE === 'true';
    const [compareAreasIdName, setCompareAreasIdNames] = useRecoilState(compareAreasIdNameState);
    const [shouldFetchDefaults, setShouldFetchDefaults] = useState(false);
    // Initialize Google Analytics tracker
    // Check if the host is not localhost before initializing
    if (window.location.hostname !== 'localhost') {
        ReactGA.initialize('G-GG6ZFSHMRX');
    }

    useEffect(() => {
        if (compareAreasIdName && compareAreasIdName.length === 0) {
            setShouldFetchDefaults(true);
        }
    }, []);

    const { data } = useQuery(AREAS_ID_NAME_BY_IDS, {
        variables: {
            ids: ['63689fc7e80bff5a9951d7c6','6368a50be80bff5a995b0581', '6368a36ae80bff5a99582918'],
        },
        skip: !shouldFetchDefaults,
    });

    useEffect(() => {
        if (data && data.areasById) {
            setCompareAreasIdNames(data.areasById);
        }
    }, [data]);

    const isPWA = window.matchMedia('(display-mode: standalone)').matches;

    function initReloadOnPWA() {
        if (isPWA) {
            // If it's a PWA, add event listeners for focus and blur
            window.addEventListener('focus', handleFocus);
            window.addEventListener('blur', handleBlur);
        }
    }
    
    function handleFocus() {
        // When the PWA gains focus, reload the page
        window.location.reload();
    }
    
    function handleBlur() {
        // Optional: You can perform additional actions when the PWA loses focus
    }
    
    // Check if it's a PWA and initialize reload functionality
    if (isPWA) {
        initReloadOnPWA();
    }

    function RedirectToPage({ page }: { page: string }) {
        const { id } = useParams();
        const redirectTo = `/${page}/${id}/redirect`;
        return <Navigate to={redirectTo} replace />;
    }

    const isWidgetRoute = location.pathname.startsWith('/widget');

    return (
        <div>
            <BrowserRouter>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
                {!isWidgetRoute && !inMaintenanceMode && <NavbarComponent/>}
                <ScrollToTop /> 
                <Routes>

                    {/* Render DownForMaintenancePage for all paths if maintenance mode is enabled */}
                    {inMaintenanceMode && <Route path="*" element={<DownForMaintenance />} />}
                    {!inMaintenanceMode && (
                        <>
                            <Route path="/areas"  element={<Navigate to="/areas/63689d0fe80bff5a994f1592/usa" replace />}/>
                            <Route path="/areas/:id/:title" element={<AreasPage />}/>
                            <Route path="/compare"  element={<ComparePage />}/>
                            <Route path="/area/:id/:title" element={<AreaPage />}/>
                            <Route path="/city/:countryCode/:stateCode/:latitude/:longitude/:name" element={<CityPage />}/>
                            <Route path="/location/:latitude/:longitude" element={<LocationPage />}/>
                            <Route path="/area/:id" element={<RedirectToPage page={'area'} />}/>
                            <Route path="/home" element={<HomePage />}/>
                            <Route path="/blogs/about" element={<AboutBlog />}/>
                            <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                            <Route path="/bug" element={<BugReport />} />
                            <Route path="/feature" element={<FeatureRequest />} />
                            <Route path="/widget/daily-forecast/:latitude/:longitude" element={<WidgetDailyForecast />}/>
                            <Route path="/widget/kaya-widget/:latitude/:longitude" element={<WidgetKaya />}/>
                            <Route
                                path="/"
                                element={<Navigate to="/home" replace />}
                            />
                            <Route path="*" element={<MissingPageComponent/>} />
                        </>
                        
                    )}
                    
                </Routes>
                {!isWidgetRoute && !inMaintenanceMode && <Footer/>}
            </BrowserRouter>
        </div>
    );
}

export default App;
