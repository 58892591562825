/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HourlyForecast as HourlyForecastType } from '../../types/Forecast';
import HourlyForecastCharts from './HourlyForecastCharts';
import HourlyForecastTable from './HourlyForecastTable';

type HourlyForecastsProps = {
    forecasts: HourlyForecastType[],
    selectedView: string,
    bestTimeToClimb?: HourlyForecastType,
    sunrise?: Date,
    sunset?: Date,
    weeklyHourlyForecast: HourlyForecastType[][],
    showWeeklyForecast: boolean,
    isMetric?: boolean
}

const HourlyForecasts = ({ forecasts, selectedView, bestTimeToClimb, sunrise, sunset, weeklyHourlyForecast, showWeeklyForecast, isMetric }: HourlyForecastsProps) => {
    
    return (
        <div>   
            <div>
                {selectedView === 'graph' && (
                    <HourlyForecastCharts
                        forecasts={forecasts}
                        weeklyHourlyForecast={weeklyHourlyForecast}
                        showWeeklyForecast={showWeeklyForecast}
                        isMetric={isMetric}
                        displayClimbitChart={true}
                    />
                )}
                { selectedView === 'table' && (
                    <HourlyForecastTable
                        forecasts={forecasts}
                        bestTimeToClimb={bestTimeToClimb}
                        sunrise={sunrise}
                        sunset={sunset}
                        isMetric={isMetric}
                    />
                )}
            </div>
        </div>
    );
};

export default HourlyForecasts;