/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { HourlyForecast } from '../../types/Forecast';
import HourlyForecastTableRow from './HourlyForecastTableRow';
import { isValueWithinHour } from '../../utils/CommonUtils';
import { BsSunrise, BsSunset } from 'react-icons/bs';
import { getDisplayTime } from '../../utils/DateUtil';

type HourlyForecastTableProps = {
    forecasts: HourlyForecast[],
    bestTimeToClimb?: HourlyForecast,
    areaName?: string;
    simple?: boolean;
    ref?: React.RefObject<HTMLDivElement>;
    currentWeather?: boolean;
    sunrise?: Date;
    sunset?: Date;
    isMetric?: boolean;
}


const HourlyForecastTable = ({ forecasts, bestTimeToClimb, areaName, simple = false, ref, currentWeather=false, sunrise, sunset, isMetric }: HourlyForecastTableProps) => {
    return (
        <div key={`hourly-table-${areaName}`} className='py-4'>
            {forecasts.map((cast: HourlyForecast, index) => (
                <div className='mx-2 md:mx-5 flex flex-col items-center justify-evenly' key={`hourly-table-${index}`}>
                    {areaName && (
                        <p  key={`hourly-table-area-name-${index}`}>{areaName}</p>
                    )}
                </div>)
            )}
            <div className="flex w-full" ref={ref}>
                <div className="relative overflow-x-scroll shadow-md sm:rounded-lg w-full md:mx-2">
                    <table className="w-full text-sm text-gray bg-white">
                        <tbody className='w-full'>
                            {forecasts.map((hourlyForecast, index) => {
                                let footer: React.ReactNode;
                                if (sunrise && isValueWithinHour(sunrise, new Date(hourlyForecast.time))) {
                                    footer = <div className='icon-styling text-gray text-xs'>
                                        <BsSunrise size={20} />
                                        {getDisplayTime(new Date(sunrise), true)}
                                    </div>;
                                } else if (sunset && isValueWithinHour(sunset, new Date(hourlyForecast.time))) {
                                    footer = <div className='icon-styling text-gray text-xs'>
                                        <BsSunset size={20} />
                                        {getDisplayTime(new Date(sunset), true)}
                                    </div>;
                                }
                                return (
                                    <React.Fragment key={`forecast-row-${index}`}>
                                        <HourlyForecastTableRow  simplify={simple} hourlyForecast={hourlyForecast} isBestTimeToClimb={ bestTimeToClimb && hourlyForecast.time === bestTimeToClimb.time} tooltipPosition={index === 0 ? 'centered' : '-top-1 -mt-9'} currentWeather={currentWeather} sunriseSunset={footer} isMetric={isMetric}/>
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

};

export default HourlyForecastTable;