import { FaSnowflake } from 'react-icons/fa6';
import { WiRaindrop } from 'react-icons/wi';
import { HourlyForecast } from '../../../types/Forecast';
import {
    getDisplayPrecipitation,
    getPrecipUnits,
} from '../../../utils/WeatherUtils';
import StarsComponent from '../../common/Stars';

type HourlyDataProps = {
  hourlyForecastData: HourlyForecast | null;
  isMetric: boolean;
};

const HourlyData: React.FC<HourlyDataProps> = ({
    hourlyForecastData,
    isMetric,
}) => {
    const precipUnit = getPrecipUnits(isMetric);

    if (!hourlyForecastData) {
        return null;
    }

    return (
        <div className="flex flex-col items-center space-y-1 w-full">
            <div className="text-center text-lg">
        Hourly Forecast:{' '}
                {hourlyForecastData?.displayTime.replaceAll(' ', '').toLowerCase() ??
          ''}
            </div>
            {hourlyForecastData.climbit.climbitStars !== undefined && (
                <StarsComponent
                    value={hourlyForecastData?.climbit.climbitStars}
                    max={5}
                    size={30}
                    tooltipPosition={''}
                />
            )}
            <div className="flex flex-row items-center w-full justify-around">
                <div className="flex flex-col items-center">
                    <div className="text-[28px] mb-2">
                        {hourlyForecastData?.temperature?.toFixed() ?? ''}°
                        {isMetric ? 'C' : 'F'}
                    </div>
                    <div className="text-sm">Feels Like</div>
                    <div className="text-2xl font-light">
                        {hourlyForecastData?.apparentTemperature.toFixed() ?? ''}°
                        {isMetric ? 'C' : 'F'}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="text-sm">
                        <span className="font-semibold">Wind</span>:{' '}
                        {hourlyForecastData?.windSpeed ?? ''}mph
                    </div>
                    <div className="text-sm flex">
                        <span className="font-semibold">Precip</span>:{' '}
                        <div className="flex">
                            {((hourlyForecastData?.rainfall > 0 &&
                !hourlyForecastData?.snowfall) ||
                (!hourlyForecastData?.rainfall &&
                  !hourlyForecastData?.snowfall)) && (
                                <div className="whitespace-nowrap flex items-center">
                                    <WiRaindrop size={12} className="scale-[2] mt-0.5 mx-0.5" />
                                    {getDisplayPrecipitation(
                                        hourlyForecastData?.rainfall,
                                        isMetric,
                                    )}
                                    {precipUnit}
                                </div>
                            )}
                            {hourlyForecastData.snowfall > 0 &&
                !hourlyForecastData.rainfall && (
                                <>
                                    <FaSnowflake size={12} className="mr-1" />
                                    {getDisplayPrecipitation(
                                        hourlyForecastData?.snowfall,
                                        isMetric,
                                    )}
                                    {isMetric ? 'cm' : precipUnit}
                                </>
                            )}
                            {hourlyForecastData.snowfall > 0 &&
                hourlyForecastData.rainfall > 0 && (
                                <div className="whitespace-nowrap flex items-center">
                                    <WiRaindrop size={12} className="scale-[2] mt-0.5 mx-0.5" />
                                    {getDisplayPrecipitation(
                                        hourlyForecastData?.rainfall,
                                        isMetric,
                                    )}
                                    {precipUnit} | <FaSnowflake size={12} className="mx-1" />
                                    {getDisplayPrecipitation(
                                        hourlyForecastData?.snowfall,
                                        isMetric,
                                    )}
                                    {isMetric ? 'cm' : precipUnit}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold">Humidity</span>:{' '}
                        {hourlyForecastData?.relativeHumidity}%
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold">Dew Point</span>:{' '}
                        {hourlyForecastData?.dewpoint}°
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold">Cloud Cover</span>:{' '}
                        {hourlyForecastData?.cloudCover ?? ''}%
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HourlyData;
