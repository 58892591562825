import { Area } from '../types/Area';
import { DailyForecast, ForecastMinMaxValues, HourlyForecast, IdealMinMax } from '../types/Forecast';

export const getDisplayPrecipitation = (precipitation: number, metricUnits: boolean): string  => {
    if (precipitation <= 0.01 && precipitation > 0) {
        return '<0.01';
    } 
    const roundedPrecipitation = Math.ceil(precipitation * 100) / 100;
    return roundedPrecipitation.toFixed(metricUnits ? 1 : 2).toString();
};

export const findBestTimeToClimb = (forecasts: HourlyForecast[] | undefined): HourlyForecast | null  => {
    if (!forecasts) return null;
    return forecasts.reduce(function(prev, current) {
        return (prev.climbit.climbitScore > current.climbit.climbitScore) ? prev : current;
    });
};

export const sortForecastsByDate = (areas: Area[], type: string) => {
    const forecastsByDate: { [date: string]: any } = {};
  
    areas.forEach((area) => {
        let forecasts;
        switch (type) {
        case 'hourlyForecast':
            forecasts = area.forecasts?.hourlyForecast;
            break;
        case 'dailyForecast':
            forecasts = area.forecasts?.dailyForecast;
            break;
        case 'bestTimeToClimb':
            forecasts = area.forecasts?.bestTimesToClimb?.hourlyForecast;
            break;
        default:
            forecasts = area.forecasts?.dailyForecast;
            break;
        }
        if (forecasts) {
            forecasts.forEach((forecast: any) => {
                if (!forecastsByDate[forecast.date]) {
                    forecastsByDate[forecast.date] = [];
                }
                forecastsByDate[forecast.date].push(forecast);
            });
        }
    });
  
    return forecastsByDate;
};

export const sortForecastsByAreaName = (forecasts: DailyForecast[] | HourlyForecast[]) => {
    const forecastsByArea: { [areaName: string]: any } = {};
    forecasts?.forEach((forecast: DailyForecast | HourlyForecast) => {
        if (!forecast.areaName) {
            return [];
        }
        if (!forecastsByArea[forecast.areaName]) {
            forecastsByArea[forecast.areaName] = [];
        }
        forecastsByArea[forecast.areaName].push(forecast);
    });
  
    return forecastsByArea;
};

export const sortForecastsByAreaId = (forecasts: DailyForecast[] | HourlyForecast[]) => {
    const forecastsByAreaId: { [areaId: string]: any } = {};
    forecasts?.forEach((forecast: DailyForecast | HourlyForecast) => {
        if (!forecast.areaId) {
            return [];
        }
        if (!forecastsByAreaId[forecast.areaId]) {
            forecastsByAreaId[forecast.areaId] = [];
        }
        forecastsByAreaId[forecast.areaId].push(forecast);
    });
  
    return forecastsByAreaId;
};

export const convertTemperature = (temperature: number, toMetric: boolean) => {
    let convertedTemperature;
    if (toMetric) {
        convertedTemperature =  (temperature - 32) * 5/9; // Fahrenheit to Celsius
    } else {
        convertedTemperature = (temperature * 9/5) + 32; // Celsius to Fahrenheit
    }
    // Round to the nearest whole number
    const roundedTemperature = Math.round(convertedTemperature);

    return roundedTemperature;
};

export const convertIdealMinMax = (idealMinMax: IdealMinMax, toMetric: boolean) => {
    const convertedTemperature = {
        ideal: {
            min: convertTemperature(idealMinMax.ideal.min, toMetric),
            max: convertTemperature(idealMinMax.ideal.max, toMetric)
        },
        minMax: {
            min: convertTemperature(idealMinMax.minMax.min, toMetric),
            max: convertTemperature(idealMinMax.minMax.max, toMetric)
        }
    };
    return convertedTemperature;
};
  
  
export const getTemperatureUnits = (isMetric: boolean) => {
    return isMetric ? '°C' : '°F';
};

export const getSpeedUnits = (isMetric: boolean) => {
    return isMetric ? 'kph' : 'mph';
};

export const getPrecipUnits = (isMetric: boolean, isSnow = false) => {
    if (isSnow && isMetric) {
        return 'cm';
    }
    return isMetric ? 'mm' : 'in';
};

export const findMinMaxWeeklyForecastValues = (weeklyForecast: HourlyForecast[][]): ForecastMinMaxValues => {
    // Initialize min and max values with the first element in the array
    let minTemp = Infinity, maxTemp = -Infinity;
    let minApparentTemperature = Infinity, maxApparentTemperature = -Infinity;
    let minQuantitativePrecipitation = Infinity, maxQuantitativePrecipitation = -Infinity;
    let minWindSpeed = Infinity, maxWindSpeed = -Infinity;
    let minSnowfall = Infinity, maxSnowfall = -Infinity;

    for (let i = 0; i < weeklyForecast.length; i++) {
        for (let j = 0; j < weeklyForecast[i].length; j++) {
            const forecast = weeklyForecast[i][j];
  
            // Update min and max values for each field
            if (forecast.temperature) {
                if (forecast.temperature < minTemp) minTemp = forecast.temperature;
                if (forecast.temperature > maxTemp) maxTemp = forecast.temperature;
            }
           
            if (forecast.apparentTemperature) {
                if (forecast.apparentTemperature < minApparentTemperature) minApparentTemperature = forecast.apparentTemperature;
                if (forecast.apparentTemperature > maxApparentTemperature) maxApparentTemperature = forecast.apparentTemperature;
            }

            if (forecast.quantitativePrecipitation) {
                if (forecast.quantitativePrecipitation < minQuantitativePrecipitation) minQuantitativePrecipitation = forecast.quantitativePrecipitation;
                if (forecast.quantitativePrecipitation > maxQuantitativePrecipitation) maxQuantitativePrecipitation = forecast.quantitativePrecipitation;
            }

            if (forecast.snowfall) {
                if (forecast.snowfall < minSnowfall) minSnowfall = forecast.snowfall;
                if (forecast.snowfall > maxSnowfall) maxSnowfall = forecast.snowfall;
            }
           
            if (forecast.windSpeed < minWindSpeed) minWindSpeed = forecast.windSpeed;
            if (forecast.windSpeed > maxWindSpeed) maxWindSpeed = forecast.windSpeed;
        }
    }
  
    return {
        minTemp,
        maxTemp,
        minApparentTemperature,
        maxApparentTemperature,
        minWindSpeed,
        maxWindSpeed,
        minQuantitativePrecipitation,
        maxQuantitativePrecipitation,
        minSnowfall,
        maxSnowfall
    };
};

export const findMinMaxForecastValues = (forecasts: HourlyForecast[]): ForecastMinMaxValues => {
    // Initialize min and max values with the first element in the array
    let minTemp = Infinity, maxTemp = -Infinity;
    let minApparentTemperature = Infinity, maxApparentTemperature = -Infinity;
    let minQuantitativePrecipitation = Infinity, maxQuantitativePrecipitation = -Infinity;
    let minWindSpeed = Infinity, maxWindSpeed = -Infinity;
    let minSnowfall = Infinity, maxSnowfall = -Infinity;

  
    for (let i = 0; i < forecasts.length; i++) {
        const forecast = forecasts[i];
  
        // Update min and max values for each field
        if (forecast.temperature) {
            if (forecast.temperature < minTemp) minTemp = forecast.temperature;
            if (forecast.temperature > maxTemp) maxTemp = forecast.temperature;
        }
           
        if (forecast.apparentTemperature) {
            if (forecast.apparentTemperature < minApparentTemperature) minApparentTemperature = forecast.apparentTemperature;
            if (forecast.apparentTemperature > maxApparentTemperature) maxApparentTemperature = forecast.apparentTemperature;
        }

        if (forecast.quantitativePrecipitation) {
            if (forecast.quantitativePrecipitation < minQuantitativePrecipitation) minQuantitativePrecipitation = forecast.quantitativePrecipitation;
            if (forecast.quantitativePrecipitation > maxQuantitativePrecipitation) maxQuantitativePrecipitation = forecast.quantitativePrecipitation;
        }

        if (forecast.snowfall) {
            if (forecast.snowfall < minSnowfall) minSnowfall = forecast.snowfall;
            if (forecast.snowfall > maxSnowfall) maxSnowfall = forecast.snowfall;
        }
           
        if (forecast.windSpeed < minWindSpeed) minWindSpeed = forecast.windSpeed;
        if (forecast.windSpeed > maxWindSpeed) maxWindSpeed = forecast.windSpeed;
    
    }
  
    return {
        minTemp,
        maxTemp,
        minApparentTemperature,
        maxApparentTemperature,
        minWindSpeed,
        maxWindSpeed,
        minQuantitativePrecipitation,
        maxQuantitativePrecipitation,
        minSnowfall,
        maxSnowfall
    };
};
