
import {useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import UserPreferencesComponent from '../user/UserPreferences';

interface NavbarProps {
    setShowMobileDraw: React.Dispatch<React.SetStateAction<boolean>>;
  }

export default function NavbarComponent({ setShowMobileDraw }: NavbarProps) {
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (navRef.current && !navRef.current.contains(event.target as HTMLElement)) {
                setShowMobileDraw(false);
            }
        };
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div ref={navRef} className='absolute z-30 bg-white w-full border-b-2 border-gray-light'>
            <ul className='md:hidden w-full font-bold space-y-2 block'>
                <li className='w-full flex border-b-2 border-gray border-opacity-30'> 
                    <Link  to={'/compare'} className='w-full flex flex-row justify-center text-center items-center py-2' onClick={() =>  setShowMobileDraw(false)}>Compare</Link>
                </li>
                <li className='w-full flex border-b-2 border-gray border-opacity-30'> 
                    <Link to={'/areas'} className='w-full flex flex-row justify-center text-center items-center py-2' onClick={() =>  setShowMobileDraw(false)}>Explore</Link>
                </li>
                <li className='flex text-center items-center justify-center w-full py-2'>
                    <UserPreferencesComponent setShowMobileDraw={setShowMobileDraw}/>
                </li>
            </ul>
        </div>
    
    );

}
