type WeatherDayCardProps = {
  day: string;
  weatherIcon: JSX.Element | undefined;
  temperature: number;
}

const WeatherDayCard: React.FC<WeatherDayCardProps> = ({ day, weatherIcon, temperature}) => {
    return (
        <div className="flex flex-col items-center space-y-1 text-lg md:text-2xl">
            <div className="font-semibold">
                {day}
            </div>
            <div className="w-12 h-12 flex items-center justify-center md:w-full md:h-full">
                {weatherIcon}
            </div>
            <div className="">
                {temperature.toFixed()}°
            </div>
        </div>
    );
};

export default WeatherDayCard;
