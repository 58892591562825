import { TypeAnimation } from 'react-type-animation';
import HomePageHeader from '../../images/Vida.png';
import LogoWhite from '../../images/logoWhite.png';
import AreasComboboxComponent from '../areas/AreasCombobox';
import { AreaIdName } from '../../types/Area';
import { City } from '../../types/City';
import { useNavigate } from 'react-router-dom';
import { createUrlSubdomain } from '../../utils/CommonUtils';


export default function ClimbitHeader() {
    const navigate = useNavigate();
    return (
        <div className="relative h-[30rem] bg-cover bg-center">
            <img
                src={HomePageHeader}
                className="h-full object-cover w-full"
                alt="image"
            />
            {/* Overlay for text */}
            <div className="absolute inset-0 bg-black opacity-30"></div>

            {/* Text centered on the left half */}
            <div className="absolute inset-0 flex justify-start items-center text-white text-left">
                <div className="md:w-1/2 p-8">
                    <div className='flex flex-row items-center'>
                        <h1 className="text-5xl font-bold">
                    Climbit
                        </h1>
                        <img
                            src={LogoWhite}
                            className='w-[3rem] ml-4'
                            alt="logo"
                        />
                    </div>
                    <div className='flex flex-row'>
                        <TypeAnimation
                            sequence={[
                                'Change the way you climb!',
                                1000,
                                'Change the way you adventure!',
                                1000,
                                'Change the way you plan!',
                                1000,
                                'Change the way you explore!',
                                1000,
                                'Change the way you project!',
                                1000,
                                'Change the way you send!',
                                1000,
                                'Change the way you prepare!',
                                1000,
                            ]}
                            wrapper="span"
                            speed={20}
                            className='text-xl font-semibold block'
                            repeat={Infinity}
                        />
                    </div>
                </div>
            </div>

            {/* Search bar at the bottom */}
            <div className="absolute bottom-0 md:w-[550px] w-[350px] left-1/2 transform -translate-x-1/2 mb-4 z-10" id='search-bar-homepage'>
                <AreasComboboxComponent
                    includeCities={true}
                    onFocus={() => {
                        const searchBar = document.getElementById('search-bar-homepage');
                        if (searchBar) {
                            setTimeout(() => {
                                const searchBarTop = searchBar.getBoundingClientRect().top + window.scrollY - 65;
                                window.scrollTo({
                                    top: searchBarTop,
                                    behavior: 'smooth',
                                });
                            }, 400);
                        }
                    }}
                    onChange={(selectedAreaCity: AreaIdName | City) => {
                        if (selectedAreaCity && 'id' in selectedAreaCity ) {
                            navigate(`/area/${selectedAreaCity.id}/${createUrlSubdomain(selectedAreaCity.name)}`);
                            window.scrollTo(0, 0);
                        } else if (selectedAreaCity && 'countryCode' in selectedAreaCity && 'stateCode' in selectedAreaCity && 'name' in selectedAreaCity) {
                            navigate(`/city/${selectedAreaCity.countryCode}/${selectedAreaCity.stateCode}/${selectedAreaCity.latitude}/${selectedAreaCity.longitude}/${createUrlSubdomain(selectedAreaCity.name)}`);
                            window.scrollTo(0, 0);
                        }
                    }} placeholderText='Start typing to search locations' />
            </div>
        </div>
    );
}
