import { ForecastMinMaxValues, HourlyForecast } from '../../../types/Forecast';
import TemperatureChart from '../../charts/TemperatureChart';
import RelativeHumidityChart from '../../charts/RelativeHumidityChart';
import PrecipitationProbabilityChart from '../../charts/PrecipitationProbabilityChart';
import ActionsButton from '../../common/Actions';
import CloudCoverChart from '../../charts/CloudCoverChart';
import WindSpeedChart from '../../charts/WindSpeedChart';
import ClimbitChart from '../../charts/ClimbitChart';
import { lineColors } from '../../../utils/CommonUtils';
import PrecipitationAmountChart from '../../charts/PrecipitationAmountChart';
import { SelectedHour } from '../../../types/LineChart';
import { RiArrowDropDownLine } from 'react-icons/ri';

const selectedGraphDisplay = {
    climbit: 'Climbit Score',
    temperature: 'Temperature',
    humidity: 'Humidity',
    'rainfall-amount': 'Rainfall Amount',
    'snowfall-amount': 'Snowfall Amount',
    'precipitation-percent': 'Precipitation Percent',
    'cloud-cover': 'Cloud Cover',
    wind: 'Wind',
};

export type SelectedGraph = keyof typeof selectedGraphDisplay;

type GraphContainerProps = {
  hourlyForecasts: HourlyForecast[];
  setSelectedGraph: React.Dispatch<React.SetStateAction<string>>;
  selectedGraph: SelectedGraph;
  weeklyHourlyForecast?: HourlyForecast[][];
  title?: string;
  height?: string;
  isMetric?: boolean;
  showWeeklyForecast?: boolean;
  forecastMinMaxValues?: ForecastMinMaxValues;
  alignment?: string;
  showLegend?: boolean;
  updateSelectedHour: (point: SelectedHour) => void;
};

export default function GraphContainer({
    hourlyForecasts,
    setSelectedGraph,
    selectedGraph,
    title,
    height = 'h-[15rem]',
    isMetric,
    weeklyHourlyForecast,
    showWeeklyForecast = false,
    alignment = '',
    forecastMinMaxValues,
    showLegend = true,
    updateSelectedHour,
}: GraphContainerProps) {
    return (
        <div className="flex flex-col w-full pt-4">
            {title && (
                <div className="card-date text-lg text-center flex items-center justify-center">
                    <span className="max-w-[75%] whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {title}
                    </span>
                </div>
            )}
            <ActionsButton
                className="font-semibold flex self-center items-center w-full"
                label={selectedGraphDisplay[selectedGraph]}
                icon={<RiArrowDropDownLine size={24}/>}
                alignment={alignment}
            >
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('climbit');
                    }}
                >
          Climbit Score
                </div>
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('temperature');
                    }}
                >
          Temperature
                </div>
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('humidity');
                    }}
                >
          Humidity
                </div>
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('rainfall-amount');
                    }}
                >
          Rainfall Amount
                </div>
                {((forecastMinMaxValues && forecastMinMaxValues.maxSnowfall > 0) ||
          weeklyHourlyForecast?.flat().some((hf) => hf.snowfall > 0)) && (
                    <div
                        className="cursor-pointer font-semibold"
                        onClick={() => {
                            setSelectedGraph('snowfall-amount');
                        }}
                    >
              Snowfall Amount
                    </div>
                )}
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('precipitation-percent');
                    }}
                >
          Precip Probability
                </div>
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('cloud-cover');
                    }}
                >
          Cloud Cover
                </div>
                <div
                    className="cursor-pointer font-semibold"
                    onClick={() => {
                        setSelectedGraph('wind');
                    }}
                >
          Wind
                </div>
            </ActionsButton>
            <div className="">
                {selectedGraph === 'climbit' && (
                    <div key={'climbit-graph'} className="snap-normal snap-center flex">
                        <ClimbitChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            showTitle={false}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            color={lineColors.climbitScoreLineColor}
                            showLegend={showLegend}
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
                {selectedGraph === 'temperature' && (
                    <div key={'temp-graph'} className="snap-normal snap-center">
                        <TemperatureChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            isMetric={isMetric}
                            showLegend={true}
                            showTitle={false}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            yMin={
                                forecastMinMaxValues
                                    ? Math.floor(
                                        Math.min(
                                            forecastMinMaxValues.minApparentTemperature,
                                            forecastMinMaxValues.minTemp,
                                        ) / 10,
                                    ) * 10
                                    : undefined
                            }
                            yMax={
                                forecastMinMaxValues
                                    ? Math.ceil(
                                        Math.max(
                                            forecastMinMaxValues.maxApparentTemperature,
                                            forecastMinMaxValues.maxTemp,
                                        ) / 10,
                                    ) * 10
                                    : undefined
                            }
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
                {selectedGraph === 'humidity' && (
                    <div
                        key={'relative-humidity-graph'}
                        className="snap-normal snap-center"
                    >
                        <RelativeHumidityChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            showTitle={false}
                            showLegend={showLegend}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
                {selectedGraph === 'rainfall-amount' && (
                    <div
                        key={'rainfall-amount-graph'}
                        className="snap-normal snap-center"
                    >
                        <PrecipitationAmountChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            isMetric={isMetric}
                            showWeeklyForecast={showWeeklyForecast}
                            showTitle={false}
                            showLegend={showLegend}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            yMax={
                                forecastMinMaxValues
                                    ? Math.ceil(
                                        forecastMinMaxValues.maxQuantitativePrecipitation * 10,
                                    ) / 10
                                    : undefined
                            }
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}

                {selectedGraph === 'snowfall-amount' && (
                    <div
                        key={'snowfall-amount-graph'}
                        className="snap-normal snap-center"
                    >
                        <PrecipitationAmountChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            isMetric={isMetric}
                            showWeeklyForecast={showWeeklyForecast}
                            showTitle={false}
                            showLegend={showLegend}
                            height={height}
                            isSnow={true}
                            shadow={false}
                            padding="p-1"
                            yMax={
                                forecastMinMaxValues
                                    ? Math.ceil(forecastMinMaxValues.maxSnowfall * 10) / 10
                                    : undefined
                            }
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
                {selectedGraph === 'precipitation-percent' && (
                    <div
                        key={'precipitation-percent-graph'}
                        className="snap-normal snap-center"
                    >
                        <PrecipitationProbabilityChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            showTitle={false}
                            showLegend={showLegend}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
                {selectedGraph === 'cloud-cover' && (
                    <div key={'cloud-cover-graph'} className="snap-normal snap-center">
                        <CloudCoverChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            showTitle={false}
                            showLegend={showLegend}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
                {selectedGraph === 'wind' && (
                    <div key={'wind-graph'} className="snap-normal snap-center">
                        <WindSpeedChart
                            hourlyForecasts={hourlyForecasts}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            isMetric={isMetric}
                            showTitle={false}
                            showLegend={showLegend}
                            height={height}
                            shadow={false}
                            padding="p-1"
                            yMax={
                                forecastMinMaxValues
                                    ? Math.ceil(forecastMinMaxValues.maxWindSpeed / 10) * 10
                                    : undefined
                            }
                            updateSelectedHour={updateSelectedHour}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
