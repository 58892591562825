import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { LineChartData, SelectedHour } from '../../types/LineChart';

type LineChartCardProps = {
    labels: string[],
    lineData: LineChartData[],
    title: string,
    showTitle?: boolean,
    yMin?: number,
    yMax?: number,
    maintainAspectRatio?: boolean,
    height?: string,
    subLabels?: string[] | null,
    shadow?: boolean,
    legend?: boolean,
    paddingClass?: string,
    labelSuffix?: string,
    axisLabelFont?: number,
    axisTitleFont?: number,
    titleFont?: number,
    yTitle?: string
    updateSelectedHour?: (point: SelectedHour) => void;
}

function getRandomColor() {
    const hue = Math.floor(Math.random() * 360); // Generate a random hue value between 0 and 360
    const saturation = Math.floor(Math.random() * 50) + 50; // Generate a random saturation value between 50 and 100
    const lightness = Math.floor(Math.random() * 20) + 40; // Generate a random lightness value between 40 and 60

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
}

export default function LineChartCard({ labels, lineData, title, yMax, yMin, maintainAspectRatio, height = 'h-[15rem]', subLabels, shadow=true, showTitle=true, legend=true, paddingClass='p-1', labelSuffix = '', axisLabelFont=10, axisTitleFont=14, titleFont=20, yTitle, updateSelectedHour}: LineChartCardProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [chart] = useState<Chart | null>(null);
    const chartInstanceRef = useRef<Chart | null>(null);
    const shadowClass = shadow ?  'shadow-lg' : '';
    const datasets = lineData.map((dataset) => {
        return {
            label: dataset.labelDescription,
            data: dataset.values,
            borderColor: dataset.color ?? getRandomColor(),
            backgroundColor: dataset.color ?? getRandomColor(),
            tension: 0.2,
            hidden: dataset.hidden,
            pointRadius: 0,
        };
    });

    React.useEffect(() => {
        // Destroy the previous chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
     
        if (canvasRef.current) {
           
            const ctx = canvasRef.current.getContext('2d');
            if (ctx) {
                chartInstanceRef.current = new Chart(
                    ctx, {
                        type: 'line',
                        options: {
                            ...(updateSelectedHour && {
                                onClick: (event, chartElements) => {
                                    if (chartElements.length > 0) {
                                        const hourIndex = chartElements[0].index;
                                        const climbitScore = datasets[0].data[hourIndex];
                                        updateSelectedHour({ hourIndex, climbitScore });
                                    }
                                },
                            }),
                            clip: false,
                            animation: false,
                            maintainAspectRatio,
                            interaction: {
                                intersect: false,
                                mode: 'index',
                            },
                            scales: {
                                x: {
                                    labels: subLabels ? labels : [...new Set(labels)],
                                    ticks: {
                                        display: true,
                                        font: {
                                            size: axisLabelFont,
                                        },
                                    },
                                },
                                x2: {
                                    labels: subLabels ? [...subLabels] : [],
                                    border: {
                                        display: false,
                                    },
                                    grid: {
                                        display: false,
                                        drawOnChartArea: false,
                                    },
                                    offset: true,
                                },
                                y: {
                                    title: {
                                        display: !!yTitle,
                                        text: yTitle ?? undefined,
                                        font: {
                                            size: axisTitleFont
                                        }
                                    },
                                    min: yMin,
                                    max: yMax,
                                    ticks: {
                                        display: true,
                                        font: {
                                            size: axisLabelFont,
                                        },
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: showTitle,
                                    text: `${title}`,
                                    color: '#000000',
                                    font: {
                                        size: titleFont,
                                        weight: 'bolder',
                                    },
                                },
                                legend: {
                                    display: legend,
                                },
                                tooltip: {
                                    callbacks: {
                                        title: function(i) {
                                            const index = Math.floor(i[0].dataIndex / 6);
                                            return subLabels
                                                ? `${i[0].label} / ${subLabels[index]} `
                                                : i[0].label;
                                        },
                                        label: function(tooltipItems) {
                                            // Get the default label
                                            const dataset =
                        tooltipItems.chart.data.datasets[
                            tooltipItems.datasetIndex
                        ];
                                            let defaultLabel = dataset.label || '';
                                            if (defaultLabel.length > 25) {
                                                defaultLabel = defaultLabel.substring(0, 25) + '...';
                                            }
                                            // Append the suffix to the default label
                                            return `${defaultLabel}: ${tooltipItems.formattedValue}${labelSuffix}`;
                                        },
                                    },
                                    bodyFont: {
                                        size: 12,
                                    },
                                    titleFont: {
                                        size: 12,
                                    },
                                    itemSort: function(a, b) {
                                        return a.formattedValue > b.formattedValue ? -1 : 1;
                                    },
                                },
                            },
                        },
                        data: {
                            datasets,
                        },
                    }
                );
            }
        }
        // Cleanup function to destroy the chart instance on unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
        };
    }, [lineData, labels]);

    const handleTouchEnd = (event: TouchEvent) => {
        if (
            event.target &&
      (event.target as HTMLElement).tagName.toLowerCase() !== 'canvas' &&
      chart
        ) {
            chart.canvas.dispatchEvent(new Event('mouseout'));
        }
    };

    useEffect(() => {
        document.addEventListener('touchend', handleTouchEnd);
        return () => {
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [chart]);

    return (
        <>
            <div
                className={`relative flex flex-col w-full ${shadowClass !== '' ? 'mb-2' : ''} rounded bg-transparent ${shadowClass}`}
            >
                {/* <div className={`rounded-t mb-0 ${paddingClass} items-center w-full bg-transparent`}>
                    <h3 className="graph-title text-center">{title}</h3>
                </div> */}
                <div className={`${paddingClass} flex-auto`}>
                    <div className={`relative ${height}`}>
                        <canvas
                            className="w-100% h-100%"
                            ref={canvasRef}
                            id="line-chart"
                        ></canvas>
                    </div>
                </div>
            </div>
        </>
    );
}
