import { useState,useRef, useEffect } from 'react';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { ForecastsByArea, HourlyForecast } from '../../types/Forecast';
import HourlyForecastTable from '../Forecast/HourlyForecastTable';

type TableCarouselProps = {
    hourlyForecastsByAreaId: ForecastsByArea;
    bestTimesToClimb: HourlyForecast[]
  }

export default function TableCarousel({ hourlyForecastsByAreaId, bestTimesToClimb } :TableCarouselProps) {
    const caro = useRef<HTMLDivElement>(null);
    const card = useRef<HTMLDivElement>(null);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [isFirst, setIsFirst] = useState<boolean>(true);
  
    const nextSlides = () => {
        if (!caro.current || !card.current) return;
        const newX = caro.current.scrollLeft + card.current.clientWidth;
        caro.current.scroll({ left: newX, behavior: 'smooth' });
        showHideButtons();
    };
    
    const prevSlides = () => {
        if (!caro.current || !card.current) return;
        const newX = caro.current.scrollLeft <= caro.current.clientWidth / 3 ? 0 : caro.current.scrollLeft - card.current.clientWidth;
        caro.current.scroll({ left: newX, behavior: 'smooth' });
        showHideButtons();
    };

    const showHideButtons = () => {
        if (!caro.current || !card.current) return;
        const scrollOffset = caro.current.scrollLeft + caro.current.clientWidth + (card.current.clientWidth / 2);
        const carouselWidth = caro.current.scrollWidth;
        setIsFirst(caro.current.scrollLeft === 0);
        setIsLast(scrollOffset >= carouselWidth);
    };
  
    const handleScroll = () => {
        showHideButtons();
    };

    useEffect(() => {
        showHideButtons();
    }, []);
  
    return (
        <div className='relative flex w-full justify-center mb-10'>
            <button
                onClick={prevSlides}
                className={ `${isFirst ? 'invisible' : 'invisible md:visible'} absolute bg-opacity-10 bg-gray md:bg-transparent rounded h-full md:h-auto md:relative left-0 px-2 z-10` }>
                <FaChevronCircleLeft
                    size={18}
                    className="basis-4 text-2xl icon-btn md:mr-5"
                />
            </button>
            
            <div
                onScroll={handleScroll}
                ref={caro} className='flex max-h-[40rem] gap-4 md:gap-8 hide-scroll-bar overflow-x-scroll snap-mandatory snap-x'>
                {Object.entries(hourlyForecastsByAreaId).map(([areaId, hourlyForecasts]) => (
                    <div key={areaId} className='snap-normal snap-center'>
                        <div className='flex justify-center sticky top-0 z-[9] bg-white-secondary'>
                            <h1
                                className="text-xl w-4/5 pb-2 text-center font-bold whitespace-nowrap overflow-hidden overflow-ellipsis"
                            >
                                {hourlyForecasts[0].areaName}
                            </h1>  
                        </div>
                        <div ref={card} className='min-w-[18rem]'>
                            <HourlyForecastTable
                                bestTimeToClimb={bestTimesToClimb.find((bestTimeToClimb) => bestTimeToClimb.areaId === areaId)}
                                forecasts={hourlyForecasts}
                                simple={true}
                            />       
                        </div>    
    
                    </div>
                ))}
            </div>
            <button onClick={nextSlides} className={ `${isLast ? 'invisible' : 'invisible md:visible'} absolute bg-opacity-10 bg-gray md:bg-transparent rounded h-full md:h-auto md:relative right-0 px-2 z-10`}>
                <FaChevronCircleRight
                    size={18}
                    className="text-2xl icon-btn md:ml-5 basis-4"
                />
            </button>
        </div>
    );
}
