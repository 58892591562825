import { useRecoilValue } from 'recoil';
import { HourlyForecast } from '../../types/Forecast';
import { LineChartData } from '../../types/LineChart';
import {
    _getCompareLineData,
    createUniqueLabels,
    lineColors,
    sortDisplayDates,
} from '../../utils/CommonUtils';
import LineChartCard from '../cards/LineChartCard';
import { precipUnitSelector } from '../../state/unitPreferencesState';
import { getPrecipUnits } from '../../utils/WeatherUtils';
import { SelectedHour } from '../../types/LineChart';

type PrecipitationAmountChartProps = {
  hourlyForecasts: HourlyForecast[];
  selectedArea?: string;
  weeklyHourlyForecast?: HourlyForecast[][];
  showTitle?: boolean;
  showLegend?: boolean;
  showWeeklyForecast: boolean;
  color?: string;
  height?: string;
  shadow?: boolean;
  padding?: string;
  isMetric?: boolean;
  yMax?: number;
  isSnow?: boolean;
  updateSelectedHour?: (point: SelectedHour) => void;
};

const PrecipitationAmountChart = ({
    hourlyForecasts,
    selectedArea,
    weeklyHourlyForecast,
    showWeeklyForecast,
    color,
    showTitle = true,
    showLegend = true,
    height = 'h-[15rem]',
    shadow = true,
    padding = 'py-2 md:px-4 px-2',
    isMetric,
    yMax,
    isSnow = false,
    updateSelectedHour,
}: PrecipitationAmountChartProps) => {
    const uniqueLabels: string[] = createUniqueLabels(
        hourlyForecasts,
        'displayTime',
    );
    const repeatingLabels: string[] = weeklyHourlyForecast
        ? weeklyHourlyForecast[0]?.map(({ displayTime }) => displayTime)
        : [];
    const labels: string[] = showWeeklyForecast
        ? repeatingLabels
        : sortDisplayDates(uniqueLabels);
    const subLabels = showWeeklyForecast
        ? Array.from(
            new Set(
                weeklyHourlyForecast?.flat().map(({ displayDate }) => displayDate),
            ),
        )
        : null;
    const precipitationUnit =
    isMetric !== undefined
        ? getPrecipUnits(isMetric)
        : useRecoilValue(precipUnitSelector);
    const lineData: LineChartData[] = showWeeklyForecast
        ? _getCompareLineData(
            weeklyHourlyForecast?.flat(),
            'climbit',
            'climbitStars',
            selectedArea,
            'displayTime',
            undefined,
            color,
        )
        : _getCompareLineData(
            hourlyForecasts,
            'climbit',
            'climbitStars',
            selectedArea,
            'displayTime',
            labels,
            color,
        );
    const title = isSnow
        ? 'Precipitation Amount (Snow)'
        : 'Precipitation Amount (Rain)';
    const labelTitle = isSnow ? 'Snow' : 'Rain';

    return (
        <>
            <div
                className={`${padding} flex flex-col items-center justify-evenly w-full min-${height}`}
            >
                <div className="flex w-full">
                    {lineData && labels && (
                        <LineChartCard
                            title={title}
                            key={'hourlyForecast_6'}
                            labels={labels}
                            lineData={[
                                {
                                    color: isSnow
                                        ? lineColors.snowLineColor
                                        : lineColors.precipitationLineColor,
                                    labelDescription: labelTitle,
                                    values:
                    showWeeklyForecast && weeklyHourlyForecast
                        ? weeklyHourlyForecast
                            .flat()
                            .map(({ rainfall, snowfall }) =>
                                isSnow ? snowfall : rainfall,
                            )
                        : hourlyForecasts.map(({ rainfall, snowfall }) =>
                            isSnow ? snowfall : rainfall,
                        ),
                                },
                            ]}
                            yMin={0}
                            maintainAspectRatio={false}
                            height={height}
                            subLabels={subLabels}
                            labelSuffix={isMetric && isSnow ? 'cm' : precipitationUnit}
                            showTitle={showTitle}
                            legend={showLegend}
                            shadow={shadow}
                            yTitle={isMetric ? (isSnow ? 'Centimeters' : 'Millimeters') : 'Inches'}           
                            yMax={yMax !== -Infinity ? yMax : undefined}
                            updateSelectedHour={updateSelectedHour}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default PrecipitationAmountChart;
