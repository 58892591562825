import { Helmet } from 'react-helmet';
import DailyForecastWidget from '../components/widgets/DailyForecastWidget';

const WidgetDailyForecast = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    return (
        <div className='w-full h-full'>
            <Helmet>
                {!currentUrl.includes('/redirect') && <link rel="canonical" href={currentUrl} />}
            </Helmet>
            <div className='justify-center mt-4 w-full h-full'>
                <DailyForecastWidget/>
            </div>   
        </div>
    );
};

export default WidgetDailyForecast;
