import { RecoilState, SetterOrUpdater, atom, atomFamily, selector, selectorFamily } from 'recoil';
import { Area, AreaIdName, Location } from '../types/Area';
import { recoilPersist } from 'recoil-persist';
import { HourlyForecast } from '../types/Forecast';
import { CompareFavorite } from '../types/Favorites';

const { persistAtom } = recoilPersist();

export const areasState = atom<Area[]>({
    key: 'areasState',
    default: [],
});

export const areaIdNameState = atom<AreaIdName>({
    key: 'areaIdName',
    default: undefined,
});

export const areaState = atom<Area | undefined>({
    key: 'areaState',
    default: undefined,
});

export const areasIdNameState = atom<AreaIdName[]>({
    key: 'areasIdNameState',
    default: [],
});

export const currentLocationState = atom<Location>({
    key: 'currentLocationState',
    default: undefined,
    effects_UNSTABLE: [persistAtom]
});

export const currentWeatherState = atom<HourlyForecast[]>({
    key: 'currentWeatherState',
    default: [],
});

export const areaIdsState = atom<string[]>({
    key: 'areaIdsState',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const favoriteAreasIdNameState = atom<AreaIdName[]>({
    key: 'favoriteAreasIdNameState',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const compareAreasIdNameState = atom<AreaIdName[]>({
    key: 'compareAreasIdNameState',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const showWeeklyForecastState = atom<boolean>({
    key: 'showWeeklyForecastState',
    default: false,
    effects_UNSTABLE: [persistAtom]
});

export const selectedViewState = atom<string>({
    key: 'selectedViewState',
    default: 'graph',
    effects_UNSTABLE: [persistAtom]
});

export const compareFavoritesState = atom<CompareFavorite[]>({
    key: 'compareFavoritesState',
    default: [],
    effects_UNSTABLE: [persistAtom]
});

export const compareFavoriteState = atom<CompareFavorite>({
    key: 'compareFavoriteState',
    default: undefined,
    effects_UNSTABLE: [persistAtom]
});

export const addAreaIdName = (
    selectedAreaIdName: AreaIdName,
    areasIdName: AreaIdName[],
    setAreasIdName: SetterOrUpdater<AreaIdName[]>
) => {
    // Check if the item already exists
    if (!areasIdName.find((areaIdName) => areaIdName.id === selectedAreaIdName.id)) {
        // Add the new item to the array
        const newArray = [selectedAreaIdName, ...areasIdName];
  
        // Sort the array alphabetically based on the 'name' property
        const sortedArray = newArray.sort((a, b) => a.name.localeCompare(b.name));
  
        // Update the Recoil state with the sorted array
        setAreasIdName(sortedArray);
    }
};

export const removeAreaIdName = (selectedAreaIdName: AreaIdName, areasIdName: AreaIdName[] , setAreasIds: SetterOrUpdater<AreaIdName[]>) => {
    setAreasIds(areasIdName.filter(areaId => areaId.id !== selectedAreaIdName.id));
};


export const addAreaId = (selectedAreaId: string, areaIds: string[] , setAreasIds: SetterOrUpdater<string[]>) => {
    if (!areaIds.includes(selectedAreaId)) {
        setAreasIds((oldArray: any) => [selectedAreaId, ...oldArray] );
    }
};

export const removeAreaId = (selectedAreaId: string, areaIds: string[] , setAreasIds: SetterOrUpdater<string[]>) => {
    setAreasIds(areaIds.filter(areaId => areaId !== selectedAreaId));
};

export const addAreaIdNameWithToast = (selectedAreaIdName: AreaIdName, areasIdName: AreaIdName[] , setAreasIdName: SetterOrUpdater<AreaIdName[]>, componentName: string, toast: any) => {
    const { name, id } = selectedAreaIdName;
    if (!areasIdName.some((area) => area.id === id)) {
        // Trigger a success toast when the area is added
        toast.success(`${name} added to ${componentName} successfully`, {
            duration: 3500,
            style: {
                background: '#363636',
                color: '#fff',
            },
        });
        addAreaIdName(selectedAreaIdName, areasIdName, setAreasIdName);
    } else {
        toast.error(`${name} already exists in ${componentName}`, {
            duration: 3500,
            style: {
                background: '#363636',
                color: '#fff',
            },
        });
    }
};


export const removeAreaIdNameWithToast = (selectedAreaIdName: AreaIdName, areasIdName: AreaIdName[] , setAreasIdName: SetterOrUpdater<AreaIdName[]>, componentName: string, toast: any) => {
    const { name } = selectedAreaIdName;

    if (areasIdName.some((area) => area.id === selectedAreaIdName.id)) {
        removeAreaIdName(selectedAreaIdName, areasIdName, setAreasIdName);
        // Trigger a success toast when the area is removed
        toast.success(`Removed ${name} from ${componentName} successfully`, {
            duration: 3500,
            style: {
                background: '#363636',
                color: '#fff',
            },
        });
    } else {
        toast.error(`${name} does not exist in ${componentName}`, {
            duration: 3500,
            style: {
                background: '#363636',
                color: '#fff',
            },
        });
    }
};

export const singleAreaState = atomFamily<Area | undefined, string>({
    key: 'singleAreaState',
    default: selectorFamily<Area | undefined, string>({
        key: 'singleAreaSelector',
        get: (id: string) => ({ get }) => {
            return get(areasState).find((area) => area.id.includes(id));
        },
    }),
});

  
export const loadingCurrentLocationState = selector({
    key: 'loadingCurrentLocationState',
    get: ({ get }) => {
        const currentLocation = get(currentLocationState);
        return !currentLocation;
    },
});
  
export const loadCurrentLocation = (set: (state: RecoilState<any>, value: any) => void) => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const { latitude, longitude } = position.coords;
                const googleMaps = `https://www.google.com/maps/place/${latitude},${longitude}`;
                
                // Update Recoil state
                set(currentLocationState, { latitude, longitude, timezone, googleMaps });

                // Resolve the promise
                resolve({ latitude, longitude, timezone, googleMaps });
            },
            (error) => {
                // Check if the error is due to user denying location access
                if (error.code === error.PERMISSION_DENIED) {
                    // Handle the case where the user denies location access
                    reject(new Error('User denied location access.'));
                } else {
                    // Handle other errors
                    reject(new Error(error.message));
                }
            }
        );
    });
};
  
// Initial setup to load the current location on Recoil state initialization
export const initializeCurrentLocation = async ({ set }: { set: (atom: any, value: any) => void }) => {
    await loadCurrentLocation(set);
};