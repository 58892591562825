import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AREAS_QUICK_LOOK_FORECASTS } from '../../graphql/areas/queries';
import Loading from '../common/Loading';
import { compareAreasIdNameState, removeAreaIdName } from '../../state/areasState';
import { ForecastMinMaxValues, HourlyForecast } from '../../types/Forecast';
import { compareAreasState } from '../../state/compareAreasState';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { weatherModelState } from '../../state/weatherModelState';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import { unitPreferencesState } from '../../state/unitPreferencesState';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import { sunShadeState } from '../../state/sunShadeState';
import { createUrlSubdomain } from '../../utils/CommonUtils';
import AreaSearchCardComponent from '../cards/AreaSearchCard';
import { useNavigate } from 'react-router-dom';
import ActionsButton from '../common/Actions';
import GraphSelector from '../common/GraphSelector';
import { findMinMaxWeeklyForecastValues } from '../../utils/WeatherUtils';

interface CompareAreasQuickLookProps {
    days?: number
}

const CompareAreasQuickLook = ({days = 3}: CompareAreasQuickLookProps) => {
    const [compareAreasIdName, setCompareAreasIdName] = useRecoilState(compareAreasIdNameState);
    const [compareAreas, setCompareAreas] = useRecoilState(compareAreasState);
    const daytimeOnly = useRecoilValue(daytimeOnlyState);
    const weatherModel = useRecoilValue(weatherModelState);
    const pastPrecipHours = useRecoilValue(pastPrecipHoursState);
    const {temperaturePreferences, metricUnits} = useRecoilValue(unitPreferencesState);
    const [weeklyHourlyForecast, setWeeklyHourlyForecast] = useState<HourlyForecast[][]>([]);
    const sunShade = useRecoilValue(sunShadeState);
    const [cards, setCards] = useRecoilState(compareAreasState);
    const [selectedGraph, setSelectedGraph] = useState<string>('climbit');
    const [forecastMinMaxValues, setForecastMinMaxValues] = useState<ForecastMinMaxValues>();
    const navigate = useNavigate();

    const { data, loading, error, refetch: refetchQuery } = useQuery(AREAS_QUICK_LOOK_FORECASTS, {
        variables: {
            ids: compareAreasIdName.map((areaIdName) => areaIdName.id),
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits,
            sunShades: compareAreasIdName.map((areaIdName) => sunShade[areaIdName.id] || 0)
        },
    });

    useEffect(() => {
        if (compareAreasIdName && compareAreasIdName.length > 0 && data && data.areasById) {
            setCompareAreas(data.areasById);
            const weeklyForecast = data.areasById.map((area: any) => area?.forecasts?.hourlyForecast.filter((_:any, i:number) => (i % 4 === 0)));
            const uniqueDates: string[] = [];
            const filteredArrayByDays: HourlyForecast[][] = weeklyForecast.map((subArray: HourlyForecast[]) => 
                subArray.filter((item: HourlyForecast) => {
                    if (uniqueDates.includes(item.displayDate)) {
                        return true;
                    }
                    if (uniqueDates.length < days && !uniqueDates.includes(item.displayDate)) {
                        uniqueDates.push(item.displayDate);
                        return true;
                    }

                    return false;
                })
            );
            setWeeklyHourlyForecast(filteredArrayByDays);
            setForecastMinMaxValues(findMinMaxWeeklyForecastValues(filteredArrayByDays));
        }
    }, [data, compareAreasIdName, compareAreas]);

    useEffect(() => {
        if (data && !loading) {
            refetchQuery();
        }
      
    }, [daytimeOnly, weatherModel, pastPrecipHours, temperaturePreferences, metricUnits, sunShade]); // Run this effect whenever preferences change

    if (error && error?.message.includes('429')) return <TooManyRequestsComponent showGoHomeButton={false} className='flex items-center justify-center bg-white-secondary md:px-20 p-4'/>;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent showBugButton={false} showGoHomeButton={false} className='flex items-center justify-center bg-white-secondary md:px-20 p-4'/>;
    return (
        <div className="page-background">
            <div className='px-1 py-4'>
                {loading && (
                    <Loading size='w-full h-full' type='relative' background='' />
                )}
                { compareAreasIdName && weeklyHourlyForecast && weeklyHourlyForecast.length > 0 && (
                    <div className='flex w-full h-full items-center justify-center'>
                        {!loading && (
                            <div className='w-full rounded-lg cursor-default'>
                                {/* <h1 className='text-center w-full md:text-4xl text-2xl font-bold'>Quick Look Compare</h1> */}
                                <div className='w-full p-0 md:p-4 md:grid md:grid-cols-1 lg:grid-cols-2 items-center justify-center md:gap-4 space-y-2 md:space-y-0'>
                                    {compareAreasIdName.map((areaIdName, i) => (
                                        <div className='cursor-default card-layout-full'> 
                                            {weeklyHourlyForecast[i] && (
                                                <div className='relative flex flex-col w-full'>
                                                    <ActionsButton className='hover:scale-105 absolute text-primary hover:text-primary-dark text-xl cursor-pointer z-[5]' alignment='right-[16px] top-[16px]'>
                                                        <div className='font-semibold text-center cursor-pointer' onClick={(event) => { removeAreaIdName(areaIdName, compareAreasIdName, setCompareAreasIdName); setCards(cards.filter((card) => card.id !== areaIdName.id)); event.stopPropagation();}}>
                                                            Remove
                                                        </div>
                                                        <div className='font-semibold text-center cursor-pointer' onClick={(event) =>{ event.stopPropagation(); navigate(`/area/${areaIdName.id}/${createUrlSubdomain(areaIdName.name)}`);}}>
                                                            Area Page
                                                        </div>
                                                        <div className='font-semibold text-center cursor-pointer' onClick={(event) =>{ event.stopPropagation(); navigate('/compare');}}>
                                                            Compare Page
                                                        </div>        
                                                    </ActionsButton>
                                                    <div>
                                                        <GraphSelector hourlyForecasts={weeklyHourlyForecast[i].filter((hourlyForecast: HourlyForecast) => hourlyForecast.displayDate === weeklyHourlyForecast[i][0].displayDate)} weeklyHourlyForecast={[weeklyHourlyForecast[i]]} height={'h-[12rem]'} showWeeklyForecast={true} title={areaIdName.name} alignment='left-[16px] top-[16px]' selectedGraph={selectedGraph} setSelectedGraph={setSelectedGraph} forecastMinMaxValues={forecastMinMaxValues} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {compareAreasIdName.length < 4 && (
                                        <div className='p-0 md:p-4 w-full justify-center items-center'>
                                            <AreaSearchCardComponent id={compareAreasIdName.length + 1} className='card-layout-full' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>    
                )}
            </div> 
        </div>
    );
};

export default CompareAreasQuickLook;