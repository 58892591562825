import { useRef } from 'react';
import { UnitPreferences } from '../../state/unitPreferencesState';
import { convertIdealMinMax } from '../../utils/WeatherUtils';

type UnitsSelectorProps = {
    currentUnitPreferences: UnitPreferences,
    setCurrentUnitPreferences: React.Dispatch<React.SetStateAction<UnitPreferences>>
}

export default function UnitsSelector({currentUnitPreferences, setCurrentUnitPreferences}: UnitsSelectorProps) {  
    // Ref to store the latest latestIsMetricUnits value
    const latestIsMetricUnitsRef = useRef<boolean>(currentUnitPreferences.metricUnits);
    latestIsMetricUnitsRef.current = currentUnitPreferences.metricUnits;
    
    const handleChange = (event:  React.ChangeEvent<HTMLSelectElement>) => {
        const isMetricUnits = event.target.value === 'true';
        const convertedTemperaturePreferences = convertIdealMinMax(currentUnitPreferences.temperaturePreferences, isMetricUnits);
        setCurrentUnitPreferences({metricUnits: isMetricUnits, temperaturePreferences: convertedTemperaturePreferences});
    };

    return (
        <div className="flex md:flex-row items-center md:justify-between space-y-1 md:space-y-0 flex-col">
            <span className='font-semibold md:text-lg text-[1rem]'>Units</span>
            <select id="models" value={currentUnitPreferences.metricUnits.toString()} onChange={e => handleChange(e)}  className="flex border border-gray text-gray text-sm rounded-lg appearance-none focus:ring-primary focus:border-primary">
                <option value="false">Imperial (ºF/mph/inch)</option>
                <option value="true">Metric (ºC/kph/mm)</option>
            </select>
        </div>
    );
}