/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HourlyForecast as HourlyForecastType } from '../../types/Forecast';
import { lineColors } from '../../utils/CommonUtils';
import TemperatureChart from '../charts/TemperatureChart';
import RelativeHumidityChart from '../charts/RelativeHumidityChart';
import PrecipitationProbabilityChart from '../charts/PrecipitationProbabilityChart';
import CloudCoverChart from '../charts/CloudCoverChart';
import WindSpeedChart from '../charts/WindSpeedChart';
import PrecipitationAmountChart from '../charts/PrecipitationAmountChart';
import SnowDepthChart from '../charts/SnowDepthChart';
import ClimbitChart from '../charts/ClimbitChart';

type HourlyForecastChartsProps = {
    forecasts: HourlyForecastType[],
    displayClimbitChart?: boolean,
    weeklyHourlyForecast?: HourlyForecastType[][],
    showWeeklyForecast: boolean,
    isMetric?: boolean
}

const HourlyForecastCharts = ({ forecasts, displayClimbitChart = false, weeklyHourlyForecast, showWeeklyForecast, isMetric }: HourlyForecastChartsProps) => {
    return (
        <>
            <div className='md:mx-5 mx-2'>
                {forecasts && weeklyHourlyForecast && (
                    <div className="py-4">
                        <div className="flex flex-col items-center justify-evenly">
                            {displayClimbitChart && (
                                <div className="flex w-full min-h-[15rem]">
                                    <ClimbitChart
                                        hourlyForecasts={forecasts}
                                        weeklyHourlyForecast={weeklyHourlyForecast}
                                        showWeeklyForecast={showWeeklyForecast}
                                        color={lineColors.climbitScoreLineColor}
                                    />
                                </div>
                            )}

                            <div className="flex w-full min-h-[15rem]">
                                <TemperatureChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isMetric={isMetric}/>
                            </div>
                            <div className="flex w-full min-h-[15rem]">
                                <RelativeHumidityChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} />
                            </div>
                            {forecasts[0] && forecasts[0].precipitationProbability !== null && (
                                <div className="flex w-full min-h-[15rem]">
                                    <PrecipitationProbabilityChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} />
                                </div>
                            )}

                            {forecasts[0] && forecasts[0].rainfall !== null && (
                                <div className="flex w-full min-h-[15rem]">
                                    <PrecipitationAmountChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isMetric={isMetric} />
                                </div>
                            )}

                            {forecasts[0] &&  forecasts[0].snowfall !== null && weeklyHourlyForecast.flat().some(forecast => forecast.snowfall > 0) && (
                                <div className="flex w-full min-h-[15rem]">
                                    <PrecipitationAmountChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isSnow={true} isMetric={isMetric} />
                                </div>
                            )}

                            {forecasts[0] &&  forecasts[0].snowfall !== null && weeklyHourlyForecast.flat().some(forecast => forecast.snowfall > 0) && (
                                <div className="flex w-full min-h-[15rem]">
                                    <SnowDepthChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isMetric={isMetric} />
                                </div>
                            )}
                            
                            <div className="flex w-full min-h-[15rem]">
                                <CloudCoverChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} />
                            </div>
                            
                            <div className="flex w-full min-h-[15rem]">
                                <WindSpeedChart hourlyForecasts={forecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isMetric={isMetric} />
                            </div>
                        </div>
                    </div>
                )}</div>
        </>
    );
};

export default HourlyForecastCharts;