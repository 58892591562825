import { useState,useRef, useEffect } from 'react';
import HourlyForecastCardComponent from './HourlyForecastCard';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import DailyForecastCardComponent from './DailyForecastCard';
import { HourlyForecast, DailyForecast } from '../../types/Forecast';

type CarouselProps = {
    hourlyForecasts?: HourlyForecast[];
    allHourlyForecasts?: HourlyForecast[];
    dailyForecasts?: DailyForecast[];
    bestTimesToClimb?: HourlyForecast[];
    onClick?: (id: number) => void;
    currentDayIndex?: number | undefined;
    displayAreaName?: boolean;
    refToScroll?: React.RefObject<any>
}
  
type ClientScroll = {
    isScrolling: boolean;
    currentX: number;
    scrollX: number;
}

export default function CardCarousel({ hourlyForecasts, allHourlyForecasts, dailyForecasts, onClick = () => {return;}, currentDayIndex, bestTimesToClimb, displayAreaName = false, refToScroll } :CarouselProps) {
    const caro = useRef<HTMLDivElement>(null);
    const card = useRef<HTMLDivElement>(null);
    const [isLast, setIsLast] = useState<boolean>(false);
    const [isFirst, setIsFirst] = useState<boolean>(true);
    const [clientScroll, setClientScroll] = useState<ClientScroll>({ isScrolling: false, currentX:0, scrollX: 0 });
    const nextSlides = () => {
        if (!caro.current || !card.current) return;
        const newX = caro.current.scrollLeft + card.current.clientWidth;
        caro.current.scroll({ left: newX, behavior: 'smooth' });
        showHideButtons();
    };
    
    const prevSlides = () => {
        if (!caro.current || !card.current) return;
        const newX = caro.current.scrollLeft <= caro.current.clientWidth / 3 ? 0 : caro.current.scrollLeft - card.current.clientWidth;
        caro.current.scroll({ left: newX, behavior: 'smooth' });
        showHideButtons();
    };

    const showHideButtons = () => {
        if (!caro.current || !card.current) return;
        const scrollOffset = caro.current.scrollLeft + caro.current.clientWidth + (card.current.clientWidth / 2);
        const carouselWidth = caro.current.scrollWidth;
        setIsFirst(caro.current.scrollLeft === 0);
        setIsLast(scrollOffset >= carouselWidth);
    };
  
    const handleScroll = () => {
        showHideButtons();
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!caro.current) return;
        setClientScroll({ isScrolling: true, currentX: e.clientX, scrollX: caro.current.scrollLeft });
    };

    const handleMouseMove = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { isScrolling, currentX, scrollX } = clientScroll;
        if (!caro.current || !isScrolling) return;
        const dx = e.clientX - currentX;
        caro.current.scrollLeft = scrollX - dx;
    };

    useEffect(() => {
        showHideButtons();
    }, [caro.current?.scrollLeft]);


    useEffect(() => {
        if (currentDayIndex !== undefined && caro.current && card.current) {
            if (caro.current && card.current) {
                // Calculate the position of the active item
                const containerWidth = caro.current.clientWidth;
                const activeItemPosition = currentDayIndex * card.current.clientWidth;
                // Calculate the scroll position to center the active item
                const scrollPosition = activeItemPosition - (containerWidth / 2) + (card.current.clientWidth / 2);
                // Scroll the carousel to the position of the active item
                caro.current.scrollTo({
                    left: scrollPosition,
                    behavior: 'smooth',
                });
            }
        }
    }, [currentDayIndex]);

    const handleClick = () => {
        if (refToScroll && refToScroll.current && refToScroll.current.getBoundingClientRect()) {
            // wait for other scroll events to finish
            setTimeout(() => {
                // fixed nav bar height adjustment
                const scrollPosition = refToScroll.current.getBoundingClientRect().top + window.scrollY - 60;
                window.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth',
                });
            }, 600);
        }
    };
  
    return (
        <div className='flex w-full justify-center mt-4'>
            <button
                onClick={prevSlides}
                className={ `${isFirst ? 'invisible' : 'invisible md:visible'} absolute bg-opacity-10 bg-gray md:bg-transparent rounded h-full md:h-auto md:relative left-0 px-2 z-10` }>
                <FaChevronCircleLeft
                    size={18}
                    className="basis-4 text-2xl icon-btn md:mr-5"
                />
            </button>

            <div
                onScroll={handleScroll}
                onMouseMove={e => handleMouseMove(e)}
                onMouseDown={e => handleMouseDown(e)}
                onMouseUp={() => setClientScroll({ ...clientScroll, isScrolling: false })}
                onMouseLeave={() => setClientScroll({ ...clientScroll, isScrolling: false })}
                ref={caro} className={`flex max-w-[1300px] gap-2 hide-scroll-bar overflow-x-scroll select-none pb-8 ${clientScroll.isScrolling ? null : 'snap-x md:snap-none'}`}>
                {dailyForecasts ? dailyForecasts.map((forecast : DailyForecast, i) =>
                    <DailyForecastCardComponent hourlyForecasts={allHourlyForecasts?.filter(
                        ({ date }: HourlyForecast) => date === forecast.date
                    )} bestTimeToClimb={bestTimesToClimb?.find((hourlyForecast: HourlyForecast) => hourlyForecast.date === forecast.date || undefined)} card={card} key={i}  id={forecast.areaId} forecast={forecast} onClick={() => {onClick(i); handleClick();}} className={`${currentDayIndex !== undefined && currentDayIndex === i ? 'border-2 border-primary' : ''} cursor-pointer snap-normal snap-center`} displayAreaName={displayAreaName} areaName={forecast.areaName}/> )
                    : hourlyForecasts ? hourlyForecasts.map((forecast: HourlyForecast, i) => 
                        <HourlyForecastCardComponent className='snap-normal snap-center' card={card} key={i} forecast={forecast} displayAreaName={false} isBestTimeToClimb={bestTimesToClimb?.some((hourlyForecast: HourlyForecast) => hourlyForecast.displayTime === forecast.displayTime)}/> )
                        : null}</div>
            <button onClick={nextSlides} className={ `${isLast ? 'invisible' : 'invisible md:visible'} absolute bg-opacity-10 bg-gray md:bg-transparent rounded h-full md:h-auto md:relative right-0 px-2 z-10`}>
                <FaChevronCircleRight
                    size={18}
                    className="text-2xl icon-btn md:ml-5 basis-4"
                />
            </button>
        </div>
    );
}
