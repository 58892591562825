import React, { useEffect, useRef, useState } from 'react';
import { Switch } from '@headlessui/react';
import DateButtons from '../buttons/DateButtons';
import { SetterOrUpdater } from 'recoil';
import { getDisplayDate } from '../../utils/DateUtil';
import { Area } from '../../types/Area';

interface ViewSelectorProps {
    selectedView: string;
    setSelected: (value: string) => void;
    showWeeklyForecast: boolean;
    setShowDailyForecast: SetterOrUpdater<boolean>;
    currentDay: string;
    currentDayIndex: number;
    setCurrentDayIndex: React.Dispatch<React.SetStateAction<number>>;
    forecastLength: number;
    compareAreas?: Area[];
}

const ViewSelector: React.FC<ViewSelectorProps> = ({
    selectedView,
    setSelected,
    showWeeklyForecast,
    setShowDailyForecast,
    currentDay,
    currentDayIndex,
    setCurrentDayIndex,
    forecastLength,
    compareAreas,
}) => {

    const [isFixed, setIsFixed] = useState(false);
    const viewSelectorRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let frameId: number;
    
        const handleScroll = () => {
            if ((selectedView !== 'graph' && selectedView !== 'table' && selectedView !== 'card' && isFixed) ){
                setIsFixed(false);
            } else if (viewSelectorRef.current && (selectedView === 'graph' || selectedView === 'table')) {
                const rect = viewSelectorRef.current.getBoundingClientRect();
                const isVisible = (rect.top >= rect.height && rect.bottom <= window.innerHeight);
                const threshold = rect.height;
                // Set isFixed to true when scrolling down
                if (rect.top <= threshold && !isVisible) {
                    setIsFixed(true);
                }
                // Set isFixed to false when scrolling up earlier
                if (rect.bottom > 110 || isVisible) {
                    setIsFixed(false);
                }
            }
    
            // Request the next animation frame
            frameId = window.requestAnimationFrame(handleScroll);
        };
    
        // Start the scroll listener with animation frame
        frameId = window.requestAnimationFrame(handleScroll);
    
        // Clean up the animation frame on component unmount
        return () => {
            window.cancelAnimationFrame(frameId);
        };
    }, [selectedView]);

    const viewSelector = <>
        {/* Switch: On the left for md and larger */}
        <div
            className={`hidden md:ml-6 ml-2 items-center md:order-first mb-4 md:mb-0 md:flex z-10 ${
                selectedView === 'graph' ? '' : 'invisible'
            }`}
        >
            <span className="font-semibold text-lg">Daily</span>
            <Switch
                checked={showWeeklyForecast}
                onChange={() => setShowDailyForecast(!showWeeklyForecast)}
                className={`${showWeeklyForecast ? 'bg-primary' : 'bg-gray opacity-70'}
            relative inline-flex h-[22px] w-[56px] ml-2 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
            >
                <span
                    aria-hidden="true"
                    className={`${showWeeklyForecast ? 'translate-x-8' : 'translate-x-0'}
                pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
            <span className="font-semibold text-lg pl-2">Weekly</span>
        </div>

        {/* Date Buttons: Centered for md and larger */}
        <div className="flex justify-center w-full order-first md:order-none md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
            {(selectedView === 'graph' || selectedView === 'table') && (
                <div className="w-full flex justify-center">
                    <DateButtons
                        currentIndex={currentDayIndex}
                        setCurrentIndex={setCurrentDayIndex}
                        length={forecastLength || 0}
                        date={getDisplayDate(new Date(currentDay))}
                    />
                </div>
            )}
        </div>

        {/* Switch and Selector: Same row on mobile */}
        <div className="flex flex-row justify-between w-full md:justify-end md:order-last">
            {/* Switch */}
            <div
                className={`flex items-center md:mb-0 mb-2 md:hidden ml-2 ${
                    selectedView === 'graph' ? '' : 'md:invisible hidden'
                }`}
            >
                <span className="font-semibold text-lg">Daily</span>
                <Switch
                    checked={showWeeklyForecast}
                    onChange={() => setShowDailyForecast(!showWeeklyForecast)}
                    className={`${showWeeklyForecast ? 'bg-primary' : 'bg-gray opacity-70'}
                relative inline-flex h-[22px] w-[56px] ml-2 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                >
                    <span
                        aria-hidden="true"
                        className={`${showWeeklyForecast ? 'translate-x-8' : 'translate-x-0'}
                    pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                </Switch>
                <span className="font-semibold text-lg pl-2">Weekly</span>
            </div>

            {/* Selector */}
            <select
                id="views"
                value={selectedView}
                onChange={(e) => setSelected(e.target.value)}
                className={`${selectedView === 'graph' ? 'md:max-w-[30rem] max-w-[8rem] ml-2 md:mr-6 mr-2' : 'flex items-center justify-center mx-auto md:flex-none md:items-end md:justify-end md:mx-0'} border border-gray text-gray text-sm rounded-lg appearance-none focus:ring-primary focus:border-primary z-10 cursor-pointer `}
            >
                <option value="graph">Graph View</option>
                <option value="table">Table View</option>
                {compareAreas &&
            compareAreas.map((area) => (
                <option value={area.id} key={`option-${area.id}`}>
                    {area.name}
                </option>
            ))}
            </select>
            
        </div>
    </>;
    return (
        <div>
            <div ref={viewSelectorRef} className={'relative flex flex-col md:flex-row w-full pt-4 px-4 pb-0 items-center'}>
                {viewSelector}
            </div>

            {isFixed && (<div  className={'fixed top-14 pb-2 z-10 bg-white shadow-md flex flex-col md:flex-row w-full pt-4 px-4 items-center'}>
                {viewSelector}
            </div>)}
        </div>
    

    );
};

export default ViewSelector;
