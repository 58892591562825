import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import Waco from '../../images/waco.jpeg';
import AreaPageGif from '../../images/gifs/AreaPage.gif';
import AreaPageMobileGif from '../../images/gifs/AreaPageMobile.gif';
import ClimbitScoreGif from '../../images/gifs/ClimbitScore.gif';
import ClimbitScoreMobileGif from '../../images/gifs/ClimbitScoreMobile.gif';
import CustomizeGif from '../../images/gifs/Customize.gif';
import CustomizeMobileGif from '../../images/gifs/CustomizeMobile.gif';
import Whip from '../../images/whip.jpg';
import CompareGif from '../../images/gifs/Compare.gif';
import CompareMobileGif from '../../images/gifs/CompareMobile.gif';
import ExploreGif from '../../images/gifs/Explore.gif';
import ExploreMobileGif from '../../images/gifs/ExploreMobile.gif';
import StarsComponent from '../../components/common/Stars';
import { isAndroidApp, isIosApp } from '../../utils/CommonUtils';
import { Helmet } from 'react-helmet';
import ClimbitHeader from '../../components/common/ClimbitHeader';

const AboutBlog = () => {
    document.title = 'Climbit - Blog | Rock Climbing Weather Forecasts';
    const isIOSApp = isIosApp();
    const isAndroid = isAndroidApp();

    return (
        <div className="min-h-screen flex flex-col">
            <Helmet>
                <title>Climbit | Rock Climbing Weather Forecasts </title>
            </Helmet>
            {/* Full-width image section */}
            <ClimbitHeader />

            <div>
                <h1 className="text-3xl font-bold  text-center pt-8 pb-4 px-6">
                    Hey climbers! We are excited to launch Climbit — the ultimate climbing weather app!
                </h1>
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-2/5 md:order-1">
                        <h1 className="text-5xl font-bold text-center">What is Climbit?</h1>
                        <p className="text-lg text-gray p-8">                            As climbers and weather nerds, we created Climbit to help you plan your climbing trips with hyper-local weather forecasts. Whether you are heading out for a local bouldering session or an epic road trip, Climbit makes it easy to find the best conditions for your adventure. Going beyond standard forecasts, Climbit features a customizable rating system to quickly assess conditions, a comparison tool to easily view your favorite climbs side-by-side, and detailed forecasts for over 47,000 areas, crags, and boulders across the United States.
                        </p>
                    </div>
                    <div className="w-full md:w-3/5 md:order-2 flex items-center justify-center">
                        <div className="w-[90%] bg-white shadow-lg rounded-lg md:mb-0 mb-8">
                            <img
                                src={AreaPageGif}
                                alt="Area Page"
                                className="object-cover h-full w-full rounded-lg md:block hidden"
                            />
                            <img
                                src={AreaPageMobileGif}
                                alt="Area Page Mobile"
                                className="object-cover h-full w-full rounded-lg block md:hidden"
                            />
                        </div>
                    </div>        
                </div>
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-2/5 md:order-2">
                        <div className='flex md:flex-row flex-col items-center justify-center'>
                            <h1 className="text-4xl font-bold text-center">The Climbit Score</h1>
                            <div className='ml-4 mt-2'>
                                <StarsComponent value={4.6} max={5}/>
                            </div>
                        </div>
                        <p className="text-lg text-gray p-8">                        Our Climbit Score algorithm simplifies weather data into a zero to five-star rating of current and forecasted climbing conditions, factoring in key variables like temperature, humidity, wind, precipitation, and cloud cover. This helps you quickly choose the best time and place to climb—whether you're tackling an epic project or enjoying a weekend bouldering session with your crew. Spend less time checking the weather and more time climbing!
                        </p>
                    </div>
                    <div className="w-full md:w-3/5 md:order-1 flex items-center justify-center">
                        <div className="w-[90%] bg-white shadow-lg rounded-lg md:mb-0 mb-8">
                            <img
                                src={ClimbitScoreGif}
                                alt="Climbit Score"
                                className="object-cover h-full w-full rounded-lg md:block hidden"
                            />
                            <img
                                src={ClimbitScoreMobileGif}
                                alt="Climbit Score Mobile"
                                className="object-cover h-full w-full rounded-lg block md:hidden"
                            />
                        </div>
                    </div>   
                   
                </div>
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-2/5 md:order-1">
                        <div className='flex md:flex-row flex-col items-center justify-center mb-2'>
                            <h1 className="text-5xl font-bold text-center">Compare Locations</h1>
                        </div>
                        <p className="text-lg text-gray p-8">                        With Climbit's compare tool, you can effortlessly compare multiple crags to find the best conditions for your next outing. Say goodbye to the hassle of manual comparisons and weather checks - Climbit does all the work, so you can focus on your climb. Whether you’re deciding between a local favorites or planning a bigger adventure, the compare tool helps you quickly find the best time and place to climb.
                        </p>
                    </div>
                    <div className="w-full md:w-3/5 md:order-2 flex items-center justify-center">
                        <div className="w-[90%] bg-white shadow-lg rounded-lg md:mb-0 mb-8">
                            <img
                                src={CompareGif}
                                alt="Compare Page"
                                className="object-cover h-full w-full rounded-lg md:block hidden"
                            />
                            <img
                                src={CompareMobileGif}
                                alt="Compare Page Mobile"
                                className="object-cover h-full w-full rounded-lg block md:hidden"
                            />
                        </div>
                    </div>   
                </div>
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-2/5 md:order-2">
                        <div className='flex md:flex-row flex-col items-center justify-center p-8'>
                            <h1 className="text-5xl font-bold text-center">Fully Customizable</h1>
                        </div>
                        <p className="text-lg text-gray px-8 py-4">                       
                            We know every climber has their own ideal conditions, so we give you full control over your Climbit Score. Customize your forecast by adjusting factors like ideal climbing temperatures, the impact of past precipitation, and how much sun or shade a climbing area gets. Plus, you can select which weather models you trust most.
                        </p>
                        <div className='text-lg text-gray p-4'>
                            <ul className='list-disc mx-8 text-left'>
                                <li>
                                    <b>Ideal Temperature Range:</b> Set your personal temperature range to ensure the Climbit Score aligns with your comfort zone.
                                </li>
                                <li>
                                    <b>Past Precipitation:</b> We include up to 7 days of past precipitation in the Climbit Score to help you assess rock conditions.
                                </li>
                                <li>
                                    <b>Sun/Shade Slider:</b> Adjust the "feels like" temperature using the sun/shade slider based on the exposure of the climbing area.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full md:w-3/5 md:order-1 flex items-center justify-center">
                        <div className="w-[90%] bg-white shadow-lg rounded-lg md:mb-0 mb-8">
                            <img
                                src={CustomizeGif}
                                alt="Customize"
                                className="object-cover h-full w-full rounded-lg md:block hidden"
                            />
                            <img
                                src={CustomizeMobileGif}
                                alt="Customize Mobile"
                                className="object-cover h-full w-full rounded-lg block md:hidden"
                            />
                        </div>
                    </div>   
                </div>
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-2/5 md:order-1">
                        <div className='flex md:flex-row flex-col items-center justify-center mb-2'>
                            <h1 className="text-5xl font-bold text-center">Explore Climbing Locations</h1>
                        </div>
                        <p className="text-lg text-gray p-8">                        
                            Climbit helps you discover over 47,000 climbing spots across the United States, powered by OpenBeta’s detailed climbing data. Whether you're exploring new areas or planning your next climbing session with friends, we make it easier to find the best time and place to climb. While Climbit currently only focuses on U.S.-based climbing locations, we plan to expand internationally in the near future.
                        </p>
                    </div>
                    <div className="w-full md:w-3/5 md:order-2 flex items-center justify-center">
                        <div className="w-[90%] bg-white shadow-lg rounded-lg md:mb-0 mb-8">
                            <img
                                src={ExploreGif}
                                alt="Explore Page"
                                className="object-cover h-full w-full rounded-lg md:block hidden"
                            />
                            <img
                                src={ExploreMobileGif}
                                alt="Explore Page Mobile"
                                className="object-cover h-full w-full rounded-lg block md:hidden mb-4"
                            />
                        </div>
                    </div>   
                </div>
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-1/2 md:order-2">
                        <div className='flex md:flex-row flex-col items-center justify-center'>
                            <h1 className="text-5xl font-bold text-center">Built by Climbers, for Climbers</h1>
                        </div>
                        <div className='p-8'>
                            <p className="text-lg text-gray mb-8">                            
                                At Climbit, we are all about making climbing more accessible and supporting the climbing community. We've partnered with OpenBeta for detailed climbing area information and Open-Meteo for accurate weather forecasts. And the best part? Climbit is completely free!
                            </p>
                            <p className="text-lg text-gray">                            
                                Climbit is a passion project, and we're constantly refining things to better serve you. We're committed to making the app better every day, and we'd love to hear your feedback! Let us know your thoughts and any features you'd like to see in future updates. If you encounter any bugs or experience downtime, we appreciate your patience as we continue to improve. 
                            </p>

                        </div>
                        <div className='flex items-center justify-center'>
                            <div className="flex flex-row space-x-2 md:space-x-4 mb-4">
                                <button
                                    className="px-4 py-1 border-2 rounded-md button shadow-md"
                                    onClick={() => {
                                        window.open('/bug');
                                    }}
                                >
                        Report a Bug
                                </button>
                                <button
                                    className="px-4 py-1 border-2 rounded-md button shadow-md"
                                    onClick={() => {
                                        window.open('/feature');
                                    }}
                                >
                        Request a Feature
                                </button>
                            </div> 
                        </div>
                       
                    </div>
                    <div className="w-full md:w-1/2 md:order-1 flex items-center justify-center mt-4">
                        <div className="w-[90%] bg-white shadow-lg rounded-lg md:block hidden">
                            <img
                                src={Whip}
                                alt="Climber taking a fall"
                                className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                            />
                        </div>
                    </div>   
                </div>
            </div>

            {isIOSApp === false && isAndroid === false && (
                <div className="flex-col-reverse md:flex md:flex-row items-center justify-center md:py-14 py-4">
                    <div className="w-full md:w-1/2 md:order-1">
                        <div className='flex md:flex-row flex-col items-center justify-center'>
                            <h1 className="text-5xl md:block hidden font-bold text-center">Download The Climbit App!</h1>
                            <h1 className="text-5xl md:hidden block font-bold text-center">Download The App!</h1>

                        </div>
                        <div className='p-8'>
                            <p className="text-lg text-gray">                            
                                Climbit is now available on both the Apple App Store and Google Play Store. Click the buttons below to download now and start planning your next climbing adventure!                        
                            </p>
                        </div>
                        <div className="w-full flex items-center justify-center">
                            <div className="flex flex-row space-x-2 md:space-x-4 mx-4">
                                <button
                                    className="px-4 py-1 border-2 rounded-md button shadow-md h-full"
                                    onClick={() => {
                                        window.open('https://apps.apple.com/us/app/climbit-score/id6517296523', '_blank', 'noreferrer');
                                    }}
                                >
                                    <div className='flex flex-row text-center items-center space-x-1'>
                                        <FaAppStoreIos size={30} /> 
                                        <p className='md:block hidden'>Apple App Store!</p>
                                        <p className='md:hidden block'>App Store!</p>

                                    </div>
                                </button>
                                <button
                                    className="px-4 py-1 border-2 rounded-md button shadow-md h-full"
                                    onClick={() => {
                                        window.open('https://play.google.com/store/apps/details?id=climbitscore.app', '_blank', 'noreferrer');
                                    }}
                                >
                                    <div className='flex flex-row text-center items-center space-x-1'>
                                        <FaGooglePlay size={30} /> 
                                        <p className='md:block hidden'>Google Play Store!</p>
                                        <p className='md:hidden block'>Play Store!</p>

                                    </div>
                                </button>
                            </div> 
                        </div>
                   
                    </div>
                    <div className="w-full md:w-1/2 md:order-2 flex items-center justify-center mt-4">
                        <div className="w-full p-6 md:mb-0 mb-8 hidden md:block">
                            <img
                                src={Waco}
                                alt="Climber about to clip"
                                className="object-cover h-[20rem] md:h-[30rem] w-full rounded-lg"
                            />
                        </div>
                    </div>   
                </div>
            )}          
        </div>
    );
};
export default AboutBlog;