import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
    AREA_ID_NAME_BY_LAT_LONG,
    LOCATION_DAILY_HOURLY_CURRENT_FORECAST,
} from '../../../graphql/areas/queries';
import { singleAreaState } from '../../../state/areasState';
import ClimbitLogo from '../../../images/climbitlogo.png';
import Loading from '../../common/Loading';
import MissingPageComponent from '../../common/MissingPage';
import { HourlyForecast } from '../../../types/Forecast';
import TooManyRequestsComponent from '../../common/TooManyRequests';
import UnknownErrorComponent from '../../common/UnknownError';
import { useQuery } from '@apollo/client';
import { createUniqueId } from '../../../utils/CommonUtils';
import DailyData from './DailyData';
import HourlyData from './HourlyData';
import WeatherDayCard from './WeatherDayCard';
import { weatherCodesMap } from '../../Forecast/WeatherIcons';
import { DailyForecastData } from './DailyData';
import GraphContainer from './GraphContainer';
import { SelectedHour } from '../../../types/LineChart';
import { findMinMaxForecastValues } from '../../../utils/WeatherUtils';

// Function to parse query parameters
const useUrlQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const KayaWidget = () => {
    const params: any = useParams();
    const query = useUrlQuery();
    const [location, setLocation] = useRecoilState<any>(
        singleAreaState(params.id),
    );
    const [areaUrl, setAreaUrl] = useState(
        `${window.location.origin}/location/${params.latitude}/${params.longitude}`,
    );
    const [dailyForecast, setDailyForecast] = useState<DailyForecastData[]>([]);
    const [hourlyForecast, setHourlyForecast] = useState<HourlyForecast | null>(
        null,
    );
    const [selectedGraph, setSelectedGraph] = useState<any>('climbit');
    const [selectedDayIndex, setSelectedDayIndex] = useState<number>(0);
    const [selectedHour, setSelectedHour] = useState<SelectedHour | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const isMetric = query.get('metric') === 'true' || false;

    // temperature const for climbit score model
    const METRIC_MIN_IDEAL = 4;
    const METRIC_MAX_IDEAL = 13;
    const METRIC_MIN_TOLERABLE = -1;
    const METRIC_MAX_TOLERABLE = 26;
    const IMPERIAL_MIN_IDEAL = 40;
    const IMPERIAL_MAX_IDEAL = 55;
    const IMPERIAL_MIN_TOLERABLE = 30;
    const IMPERIAL_MAX_TOLERABLE = 78;

    const queryOptions = {
        daytimeOnly: false,
        weatherModel: 'best_match',
        pastPrecipHours: 12,
        temperaturePreferences: {
            ideal: isMetric
                ? { min: METRIC_MIN_IDEAL, max: METRIC_MAX_IDEAL }
                : { min: IMPERIAL_MIN_IDEAL, max: IMPERIAL_MAX_IDEAL },
            minMax: isMetric
                ? { min: METRIC_MIN_TOLERABLE, max: METRIC_MAX_TOLERABLE }
                : { min: IMPERIAL_MIN_TOLERABLE, max: IMPERIAL_MAX_TOLERABLE },
        },
        metricUnits: isMetric,
    };

    // query with options defined above
    const { data, loading, error } = useQuery(
        LOCATION_DAILY_HOURLY_CURRENT_FORECAST,
        {
            variables: {
                name: createUniqueId(
                    Number(params.latitude),
                    Number(params.longitudes),
                ),
                latitude: params.latitude,
                longitude: params.longitude,
                ...queryOptions,
            },
        },
    );

    // attempt to classify lat long into known area
    const { data: foundArea } = useQuery(AREA_ID_NAME_BY_LAT_LONG, {
        variables: {
            name: params.name || '',
            latitude: params.latitude,
            longitude: params.longitude,
            ...queryOptions,
        },
    });

    // check if lat/long is at defined location, upate ClimbitScore.com link to found location
    useEffect(() => {
        if (foundArea && foundArea.areaByLatLong && foundArea.areaByLatLong.id) {
            setAreaUrl(
                `${window.location.origin}/area/${foundArea.areaByLatLong.id}`,
            );
        }
    }, [foundArea]);

    // populate daily and hourly state
    useEffect(() => {
        if (data?.location?.forecasts) {
            setLocation(data.location);

            // Create object used for DailyData prop
            const dailyForecastData = data.location.forecasts.dailyForecast.map(
                (day: DailyForecastData, index: number) => ({
                    ...day,
                    dayIndex: index,
                    peakClimbitScore:
            data.location.forecasts.bestTimesToClimb.hourlyForecast[index]
                .climbit.climbitStars,
                    peakTime:
            data.location.forecasts.bestTimesToClimb.hourlyForecast[index]
                .displayTime,
                }),
            );
            setDailyForecast(dailyForecastData);

            // default hourlyForecast to currentWeather
            setHourlyForecast(data.location.forecasts.currentWeather);
        }
    }, [data]);

    // set 'Hourly Forecast' section to peak hour when changing day
    useEffect(() => {
        if (data) {
            const peakHourlyData =
        data.location.forecasts.bestTimesToClimb.hourlyForecast[
            selectedDayIndex
        ];

            setHourlyForecast(peakHourlyData);
        }
    }, [selectedDayIndex]);

    // update hourly data when selected hour is changed
    useEffect(() => {
        if (data) {
            // update hourly forecast data to selected hour of current day
            const hourlyForecast = data.location.forecasts.hourlyForecast.filter(
                (hourData: HourlyForecast) =>
                    hourData.date === dailyForecast[selectedDayIndex]?.date,
            )[selectedHour?.hourIndex ?? 0];

            if (hourlyForecast) {
                setHourlyForecast(hourlyForecast);
            }
        }
    }, [selectedHour]);

    // add window resize event listener
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // display the first 5 items for < 630px, first 4 for < 460px
    const visibleDailyForecast =
    windowWidth < 460
        ? dailyForecast.slice(0, 4)
        : windowWidth < 630
            ? dailyForecast.slice(0, 5)
            : dailyForecast;

    if (
        isNaN(params.latitude) ||
    isNaN(params.longitude) ||
    (!location && !loading)
    ) {
        return <MissingPageComponent />;
    } else if (error) {
        if (error?.message.includes('429')) {
            return <TooManyRequestsComponent />;
        } else {
            return <UnknownErrorComponent />;
        }
    }

    return (
        <div className="h-full w-full">
            <div className="flex justify-center mb-4 flex-col items-center">
                <div className="bg-white-pure max-w-[46rem] w-[95%] h-full overflow-hidden border-2 rounded-lg shadow-md">
                    {loading && <Loading type="flex" size="w-full h-full" />}
                    {!loading && (
                        <div className="flex flex-col h-full p-4 space-y-2">
                            <div className="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
                                <div className="basis-2/5 flex flex-col space-y-2 md:w-2/5">
                                    <DailyData
                                        dailyForecastData={dailyForecast[selectedDayIndex]}
                                        isMetric={queryOptions.metricUnits}
                                    />
                                    <HourlyData
                                        hourlyForecastData={hourlyForecast}
                                        isMetric={queryOptions.metricUnits}
                                    />
                                </div>
                                <div className="basis-3/5 md:w-3/5">
                                    <div className="">
                                        {dailyForecast[selectedDayIndex] && (
                                            <GraphContainer
                                                hourlyForecasts={data.location.forecasts.hourlyForecast?.filter(
                                                    ({ date }: HourlyForecast) =>
                                                        date === dailyForecast[selectedDayIndex].date,
                                                )}
                                                setSelectedGraph={setSelectedGraph}
                                                selectedGraph={selectedGraph}
                                                height="h-[12rem] md:h-[17.8rem]"
                                                isMetric={queryOptions.metricUnits}
                                                alignment="top-0"
                                                showLegend={false}
                                                updateSelectedHour={setSelectedHour}
                                                forecastMinMaxValues={findMinMaxForecastValues(
                                                    data.location.forecasts.hourlyForecast,
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                {visibleDailyForecast.map((day: DailyForecastData) => (
                                    <button
                                        key={day?.dayIndex}
                                        className={`py-2 px-4 rounded-md ${selectedDayIndex === day?.dayIndex
                                            ? 'bg-gray-light border border-gray-dark hover:bg-[#c8c9cc]'
                                            : 'bg-white-pure'
                                        } duration-150 hover:bg-gray-light`}
                                        onClick={() => setSelectedDayIndex(day?.dayIndex)}
                                    >
                                        <WeatherDayCard
                                            day={day?.displayDate.split(' ')[0] ?? ''}
                                            weatherIcon={weatherCodesMap(true, 56).get(
                                                day?.weatherCodes.overall.code.toString(),
                                            )}
                                            temperature={day?.maxTemperature ?? ''}
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-3 flex flex-row space-x-3 cursor-pointer">
                    <a
                        target="_blank"
                        href={areaUrl}
                        className="text-primary no-underline hover:underline hover:text-primary-dark text-lg"
                    >
            Powered by ClimbitScore.com
                    </a>
                    <img
                        className="h-[32px] inline-block"
                        src={ClimbitLogo}
                        alt="climbit logo"
                    />
                </div>
            </div>
        </div>
    );
};

export default KayaWidget;
