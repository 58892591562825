import { useEffect, useRef } from 'react';
import { Chart, BarElement, LineElement, CategoryScale, LinearScale, Legend, Tooltip, ChartConfiguration } from 'chart.js';
import { useRecoilValue } from 'recoil';
import { precipUnitSelector, unitPreferencesState } from '../../state/unitPreferencesState';
import { PastPrecip } from '../../types/Forecast';
import { lineColors } from '../../utils/CommonUtils';

type PastPrecipProps = {
    pastPrecip: PastPrecip;
};

// Register necessary Chart.js components
Chart.register(BarElement, LineElement, CategoryScale, LinearScale, Legend, Tooltip);

const PrecipChart = ({ pastPrecip }: PastPrecipProps) => {
    const precipUnit = useRecoilValue(precipUnitSelector);
    const { metricUnits } = useRecoilValue(unitPreferencesState);

    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart | null>(null);

    useEffect(() => {
        const { pastPrecipAmounts, pastPrecipRunningTotals } = pastPrecip;

        const reversedPastPrecipAmounts = [...pastPrecipAmounts].reverse();
        const reversedPastPrecipRunningTotals = [...pastPrecipRunningTotals].reverse();

        // Destroy the previous chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const rainAmounts = reversedPastPrecipAmounts.map((day) => day.rain || 0);
        const rainRunningTotals = reversedPastPrecipRunningTotals.map((total) => total.rain || 0);

        const snowAmounts = reversedPastPrecipAmounts.map((day) => day.snow || 0);
        const snowRunningTotals = reversedPastPrecipRunningTotals.map((total) => total.snow || 0);

        const hasSnow = snowAmounts.some(amount => amount > 0);

        const yTitle = metricUnits ? (hasSnow ? 'cm/mm' : 'Millimeters') : 'Inches';

        const labels = reversedPastPrecipAmounts.map((_, index) => {
            let label = `${index} Days Ago`;
            if (index === 0 ) {
                label = 'So Far Today';
            } else if (index === 1 ) {
                label = 'Yesterday';
            }
            return label;       
        });

        // Conditionally add snow datasets if there are any snow values
        const datasets = [
            // Rain Bar Dataset
            {
                type: 'bar' as const,
                label: `Rain (${precipUnit})`,
                data: rainAmounts,
                backgroundColor: lineColors.precipitationLineColorOpaque,
                borderColor: lineColors.precipitationLineColor,
                borderWidth: 1,
            },
            {
                type: 'line' as const,
                label: `Rain Totals (${precipUnit})`,
                data: rainRunningTotals,
                borderColor: lineColors.precipitationLineColor,
                backgroundColor: lineColors.precipitationLineColor,
                borderWidth: 2,
                fill: false,    
            },
        ];

        // Only add snow datasets if there are snow values
        if (hasSnow) {
            datasets.push(
                // Snow Bar Dataset
                {
                    type: 'bar' as const,
                    label: `Snow (${metricUnits ? 'cm' : precipUnit})`,
                    data: snowAmounts,
                    backgroundColor: lineColors.snowLineColorOpaque,
                    borderColor: lineColors.snowLineColor,
                    borderWidth: 1,
                },
                // Snow Line Dataset
                {
                    type: 'line' as const,
                    label: `Snow Totals (${metricUnits ? 'cm' : precipUnit})`,
                    data: snowRunningTotals,
                    borderColor: lineColors.snowLineColor,
                    backgroundColor: lineColors.snowLineColor,
                    borderWidth: 2,
                    fill: false,
                }
            );
        }

        const data = {
            labels,
            datasets,
        };

        const config: ChartConfiguration<'bar' | 'line'> = {
            type: 'bar',
            data,
            options: {
                clip: false,
                responsive: true,  
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                plugins: {
                    legend: {
                        position: 'top',
                    },
                },
                scales: {
                    x: {
                        stacked: false,
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: yTitle ,
                            font: {
                                size: 14
                            }
                        },
                    },
                },
            },
        };

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, config);
            }
        }

        // Cleanup function to destroy the chart instance on unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
        };
    }, [pastPrecip, precipUnit]);

    return (
        <>
            <div className="relative flex flex-col w-full md:h-full h-[16rem] rounded bg-white">
                <div className="flex-auto">
                    <div className="relative w-full md:h-full h-[16rem] ">
                        <canvas ref={chartRef} className="w-full h-full" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrecipChart;
